import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import SubjectService from "../services/subjects.service";

interface IAddTopicModal {
    show: any,
    handleClose: any
}

export default function AddTopicModal({ show, handleClose }: IAddTopicModal) {
    const { id }: any = useParams();

    const [data, setData] = useState<any>({
        name: "",
        sub_topics: []
    });


    const addTopic = () => {
        const oldTopics = data.sub_topics || [];
        oldTopics.push({
            name: ""
        });

        const updatedSubject = {
            ...data,
            sub_topics: oldTopics
        };

        setData(updatedSubject);
    };

    const deleteTopic = (topicIndex: number) => {
        const oldTopics = [...data.sub_topics];
        oldTopics.splice(topicIndex, 1);

        const updatedSubject = {
            ...data,
            sub_topics: oldTopics
        };

        setData(updatedSubject);
    };

    const handleTopicChange = (index: number, e: any) => {
        const newTopics = [...data.sub_topics];
        newTopics[index].name = e.target.value;
        setData({ ...data, sub_topics: newTopics });
    };

    const handleAddTopic = async () => {
        await SubjectService.insertTopic(id, data).then((res) => {
            if (res.status === 200) {
                toast.success("Topic Added")
                handleClose();
            }
        }).catch(err => {
            toast.success(err.response)
        })
    }

    function onModalClose(){
        setData({
            name: "",
            sub_topics: []
        })
        handleClose();
    }

    return (
        <Modal show={show} onHide={onModalClose} animation={true} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Topic</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="form-lable">Topic Name</Form.Label>
                            <Form.Control name="name" className="form-control" onChange={(e: any) => setData({ ...data, name: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label className="form-label">Sub Topics</Form.Label>
                            <div>
                                {data &&
                                    data.sub_topics &&
                                    data.sub_topics.length > 0 &&
                                    data.sub_topics.map((topic: any, index: number) => (
                                        <Row className="mt-2 align-items-center" key={index}>
                                            <Col md={10}>
                                                <Form.Control
                                                    type="text"
                                                    value={topic.name}
                                                    onChange={(e) => handleTopicChange(index, e)}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    className="text-danger"
                                                    onClick={() => deleteTopic(index)}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                            </div>
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-4">
                            <Button size="sm" onClick={addTopic}>
                                Add Sub-topic
                            </Button>
                        </div>
                        <div className="d-flex mt-3" style={{ float: "right" }}>
                            <Button onClick={handleAddTopic}>Add</Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}