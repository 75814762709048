import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";
import QAService from "../../services/qa.service";
import QuestionNotVerified from "./NotVerifiedTable/QuestionNotVerified";
import SubjectSelection from "./SelectSubject/SubjectSelection";

export default function QAIndex() {

    return (
        <div>
            <div className="mt-4">
                <div>
                    <SubjectSelection />
                </div>
            </div>
        </div>

    )
}