import React, { useEffect, useState } from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Modal, ModalFooter,
    ModalHeader, ModalBody
} from "reactstrap"
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import toast from 'react-hot-toast';
import QP_GenerationService from '../../services/qp_generation.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import { useLoader } from "../../../components/Loader/Loader";

interface IQPModal {
    toggle: any,
    modal: boolean,
    qpDets: any,
    finalArr: any
}

function QPModal({ toggle, modal, qpDets, finalArr }: IQPModal) {
    console.log("INSIDE MODAL : ", qpDets)
    // const { loading, setLoading } = useLoader();
    const generate = async () => {
        // finalArr this will be passed here
    }
    return (
        <>
            <Modal isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 550 }}
                className="m-3"
                style={{ maxWidth: "100%" }}
            >
                <ModalHeader toggle={toggle}>Question Paper Structure</ModalHeader>
                <ModalBody>
                    <Table striped bordered hover className="my-3">
                        <thead>
                            <tr>
                                <th>Subject Name</th>
                                <th>Topic Name</th>
                                <th>Question Type</th>
                                <th>Language</th>
                                <th>No Of Questions</th>
                                <th>Difficulty</th>
                                <th>Availability</th>
                            </tr>
                        </thead>
                        <tbody>
                            {qpDets.length === 0 && <div className="p-3">"No sections were created"</div>}

                            {qpDets.length > 0 && qpDets.map((section:any) => (
                                <tr>
                                    <td>{section.subjectName}</td>
                                    <td>{section.topicName}</td>
                                    <td>{section.qType}</td>
                                    <td>{section.language}</td>
                                    <td>{section.noOfQuestions}</td>
                                    <td>{section.difficulty}</td>
                                    <td className='text-center'>
                                        {" "}
                                        <FontAwesomeIcon
                                            icon={section.availability ? faCheck : faTimes}
                                            className={section.availability ? "text-success" : "text-danger"}
                                        />
                                        {" "}
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </Table>

                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={toggle}>Close</button>
                    <button className="btn btn-primary" onClick={() => generate()}>Generate this question paper</button>{' '}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default QPModal;
