import React from 'react'
import { Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

export default function QuestionCardLoader() {
    return (
        <>
            <Card className="mt-3 shadow-lg">
                <Card.Body>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Difficulty:</p>
                                    <p className="ms-2"><Skeleton width={80} /></p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Frequency:</p>
                                    <p className="ms-2"><Skeleton width={80} /></p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Question type:</p>
                                    <p className="ms-2"><Skeleton width={80} /></p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Option Type:</p>
                                    <p className="ms-2"><Skeleton width={80} /></p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bold">Exam Category:</p>
                                    <p className="ms-2"><Skeleton width={80} /></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Skeleton count={5} height={40} />
                </Card.Body>
            </Card>
        </>
    )
}