import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./theme.scss";
import MainRouter from './Router';
import { Toaster } from 'react-hot-toast';
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-loading-skeleton/dist/skeleton.css'


function App() {
  return (
    <div className="App">
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      <MainRouter />
    </div>
  );
}

export default App;
