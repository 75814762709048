
import { Col, Row } from 'react-bootstrap';
import Cards from '../../components/Cards/Cards';
import ViewService from '../../services/view.service';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function DashboardIndex() {

    const [stats, setStats] = useState<any>();
    const [loading, setLoading] = useState(false);

    const getStats = async () => {
        setLoading(true);
        await ViewService.getStats().then((res) => {
            setStats(res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false))
    }
    useEffect(() => {
        getStats();
    }, [])

    if (loading) {
        return (
            <>
                <Row>
                    {
                        Array(4).fill(0).map((_, index) => (
                            <Col md={4} key={index}>
                                <Skeleton height={250} />
                            </Col>
                        ))
                    }
                </Row>
            </>
        )
    }



    return (
        <>
            <Row>
                <Col md={4}>
                    <Cards
                        title="Total Questions Uploaded"
                        amount={stats?.total}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Verified Questions"
                        amount={stats?.verified}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Questions to be Verified"
                        amount={stats?.pendingQuestionsToBeVerified}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="In Review Questions"
                        amount={stats?.reviewQuestions}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Total Question Papers"
                        amount={stats?.questionPapers}
                    />
                </Col>
            </Row>

        </>
    )
}