import React, { useState } from 'react'
import { Row, Col, Form } from "react-bootstrap"
import UploadService from '../../services/upload.service';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons';

interface IExplanation {
    data: any,
    setData: any,
    index: number
}

export default function Explanation(props: IExplanation) {

    const handleExplanationData = async (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const files = e.target.files;

        if (files) {
            const updatedExplanations = props.data.explanation ? [...props.data.explanation] : [];

            if (!updatedExplanations[props.index]) {
                updatedExplanations[props.index] = {};
            }

            if (!updatedExplanations[props.index][name]) {
                updatedExplanations[props.index][name] = [];
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                let formData = new FormData();
                formData.append('file', file);
                toast.promise(UploadService.uploadMultimedia(formData).then((res) => {
                    if (res.status === 200) {
                        updatedExplanations[props.index][name].push(res.data);
                    }
                }), {
                    loading: 'Uploading',
                    success: 'Uploaded',
                    error: 'Something went wrong when uploading Multimedia',
                });
            }

            props.setData({ ...props.data, explanation: updatedExplanations });
        }
    };





    const handleQuestionText = (e: any) => {
        const text = e.target.value;
        const updatedExplanations = props.data.explanation ? [...props.data.explanation] : [];

        if (!updatedExplanations[props.index]) {
            updatedExplanations[props.index] = { text: [""] };
        }

        updatedExplanations[props.index].text[0] = text;

        props.setData({ ...props.data, explanation: updatedExplanations });
    };








    return (
        <>
            <h5 className="mt-3 mb-2">Explanation</h5>
            <div>
                <Row className="mb-2">
                    <Col md={8}>
                        <Form.Group>
                            <Form.Label>Text</Form.Label>
                            <Form.Control as="textarea" rows={11} cols={5} onChange={(e: any) => handleQuestionText(e)} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label>
                            <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExplanationData(e, "image")} multiple />
                        </div>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label>
                            <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExplanationData(e, "audio")} multiple />
                        </div>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label>
                            <Form.Control type="file" name="video" accept="video/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExplanationData(e, "video")} multiple />
                        </div>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label>
                            <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExplanationData(e, "doc")} multiple />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
