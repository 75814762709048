import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'
import { ExamCategoryService } from '../../services/examCategory.service'
import AddCategoryModal from '../../Modal/AddExamCategory.modal'
import CustomToggle from '../../Menu/CustomMenu'
import EditCategoryModal from '../../Modal/EditExamCategory.modal'

export default function ExamCategory() {

    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)
    const [showEditCategoryModal, setShowEditCategoryModal] = useState<number>(-1)
    const [categoriesData, setCategoriesData] = useState<any>();

    const getAllExamCategories = async () => {
        await ExamCategoryService.getAllCompanyExamCategories().then((res) => {
            if (res.status === 200) {
                setCategoriesData(res.data.examCategories)
            }
        })
    }

    const handleDeleteExamCategory = async (id: any) => {
        await ExamCategoryService.deleteExamCategory(id).then((res) => {
            if (res.status === 200) {
                getAllExamCategories();
                toast.success("Deleted")
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }

    useEffect(() => {
        getAllExamCategories()
    }, [])

    return (
        <>
            <div className='mt-5'>
                <div className="d-flex justify-content-between align-items-center">
                    <h5>Exam Category</h5>
                    <Button size="sm" onClick={() => setShowCategoryModal(true)}>Add Category</Button>
                </div>
                <div className='mt-3'>
                    {categoriesData && categoriesData.length > 0 && categoriesData.map((data: any, index: number) => {
                        return (
                            <Card className="mb-2">
                                <Card.Body>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center gap-3">
                                                <h6 className='fw-bold'>Name:</h6>
                                                <span className="mb-2">{data.name}</span>
                                            </div>
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        as={CustomToggle}
                                                        id="dropxdown-custom-components"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEllipsisVertical}
                                                            className="text-primary xcn-link-pointer"
                                                        />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => setShowEditCategoryModal(index)}>
                                                            Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleDeleteExamCategory(data._id)}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <h6 className='fw-bold'>Created At:</h6>
                                            <span className="mb-2">{moment(data.createdAt).format("DD-MM-YYYY")}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <h6 className='fw-bold'>Allowed:</h6>
                                            <span className="mb-2">
                                                <Form.Switch checked={data.allowed} />
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </div>
            </div>

            <AddCategoryModal
                show={showCategoryModal}
                handleClose={() => { setShowCategoryModal(false); getAllExamCategories() }}
            />

            <EditCategoryModal
                show={showEditCategoryModal >= 0 ? true : false}
                handleClose={() => { setShowEditCategoryModal(-1); getAllExamCategories() }}
                categoryData={categoriesData && categoriesData[showEditCategoryModal]}
            />
        </>
    )
}
