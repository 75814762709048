import React from 'react'
import UploadPdfForm from './UploadPdfForm'

const UploadPdfIndex = () => {
    return (
        <>
            <UploadPdfForm />
        </>
    )
}

export default UploadPdfIndex