import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Select from 'react-select';
import toast from 'react-hot-toast';
// import '../Topics/Topics.css'
import TopicTabs from './TopicTabs'
import UserProfileService from '../../services/userprofile.service';
import TextEditor from '../../components/TextEditor/TextEditor';

const QPGenerationIndex = () => {

    const [subjectsList, setSubjectsList] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])
    // let [options, setOptions] = useState([]);
    let [opt, setOpt] = useState([]);

    useEffect(() => {
        // setLoading(true)
        UserProfileService.getAllSubjects().then((res) => {
            if (res.status === 200) {
                setSubjectsList(res.data);
                // setLoading(false)
                // let arr = res.data.map(data => { return { label: data.name, options: data.topics } })
                // setOptions(arr);
                let ar2 = res.data.map((data: any) => { return { ...data, value: data._id, label: data.name } });
                setOpt(ar2)
            }
        })
            .catch((err) => {
                console.log(err);
                // setLoading(false)
                toast.error("something wents wrong")
            });
    }, [])

    return (
        <>
            <div className="p-3 reg-card box-shadow card">
                <h4>Question Paper Generation</h4>
                <hr />
                <h5>Select Subjects</h5>
                <div className='col-md-6'>
                    <Select
                        defaultValue={[]}
                        isMulti
                        name="subjects"
                        options={opt}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e: any) => setSelectedSubjects(e)}
                        isSearchable
                    />
                </div>
                <TopicTabs subjects={selectedSubjects} />
            </div>
            {/* <TextEditor /> */}
        </>
    )
}

export default QPGenerationIndex