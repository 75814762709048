import React from 'react'
import { Card } from 'react-bootstrap'

interface CardProps{
    title:string,
    amount:number
}

const Cards = ({
    title, amount
}: CardProps) => {
    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>
                        {title}
                    </Card.Title>
                    <Card.Text
                        style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                        }}
                    >
                        {amount}
                    </Card.Text>
                </Card.Body>
            </Card>

        </>
    )
}

export default Cards