import { faArrowRightLong, faEye, faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Button, Card, Col, Form, Image, Row } from "react-bootstrap"
import { TranslatorService } from "../../services/translator.service"
import { useEffect, useState } from "react"
import PreviewModal from "../../Modal/preview.modal"
import AudioPlayer from 'react-h5-audio-player';
import toast from "react-hot-toast"

interface IMultimedia {
    allowed: any
    isEdit: boolean,
    handleMultiMediaUpload: any,
    handleQuestionAttachements: any,
    setMultimediaAttachmentData: any,
    multimediaAttachmentData: any,
    setQuestionText: any,
    selectedLanguage: any,
    selectedEditLanguage: any,
    reload: any,
    allowed_id: any,
    questionText: any
}

export default function Multimedia(props: IMultimedia) {


    const [encData, setEncData] = useState<any>()
    const [encVideo, setEncVideo] = useState<any>("");
    const [encAudio, setEncAudio] = useState<any>();

    const [attachmentsData, setAttachmentsData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>();
    const [toggleQuestion, setToggleQuestion] = useState<boolean>(false);




    const handleTranslateLangugae = async () => {
        const payload = {
            sourceQuestionId: props.allowed_id,
            convLang: props?.selectedEditLanguage,
        }
        toast.promise(
            TranslatorService.translateLanguage(payload).then((res) => {
                if (res.status === 200) {
                    toast.success("Successful");
                    setToggleQuestion(true);
                    props.setQuestionText(res.data.translatedText)
                }
            }),
            {
                loading: 'Uploading...',
                success: "Successful",
                error: "Unable to translate this question.",
            }
        )
    }


    const getEncMediaImage = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.image).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }

    const getEncMediaVideo = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res.data.toString());
            }
        }))
    }

    const getEncMediaAudio = async () => {
        await TranslatorService.getEncMultimedia(props.allowed.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res.data);
            }
        }))
    }

    const getEncAttachmentsImage = async (link: string) => {
        await TranslatorService.getEncMultimedia(link).then((res => {
            if (res.status === 200) {
                setAttachmentsData(res.data);
            }
        }))
    }


    const handleDelete = (index: number) => {
        props.setMultimediaAttachmentData((arr: any) => arr.filter((_: any, i: number) => i != index))
    }

    useEffect(() => {
        if (props?.allowed?.image) {
            getEncMediaImage();
        }
    }, [props?.allowed?.image])

    useEffect(() => {
        if (props?.allowed?.audio) {
            getEncMediaAudio();
        }
    }, [props?.allowed?.audio])

    useEffect(() => {
        if (props?.allowed?.video) {
            getEncMediaVideo();

        }
    }, [props?.allowed?.video])


    return (
        <>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Image</Form.Label><br />
                            {props?.allowed?.image ?
                                <div className="mb-4">
                                    <Image src={encData && encData.split(";base64,")[1] ? encData : "data:image/jpeg;base64," + encData} className="w-100" />
                                </div> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Image File Uploaded</span>
                                </Alert>}
                        </Col>
                        {props.isEdit &&
                            <div className="mt-3">
                                <hr />
                                <Row>
                                    <Col>
                                        <div>
                                            <Alert>
                                                <span>Transcript language from <strong>{props.selectedLanguage}</strong> <FontAwesomeIcon icon={faArrowRightLong} /> <strong>{props.selectedEditLanguage}</strong></span>
                                            </Alert>
                                            <div className="d-flex justify-content-end">
                                                <Button onClick={handleTranslateLangugae}>Translate</Button>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <Form.Label className="text-primary" style={{ fontSize: "500" }}>Upload Question Text</Form.Label>
                                            <Form.Check className="ms-2" checked={toggleQuestion} onChange={(e: any) => setToggleQuestion(e.target.checked)} />
                                        </div>
                                    </Col>
                                </Row>
                                {toggleQuestion &&
                                    <Row>
                                        <Col md={12} className='mb-2'>
                                            <Form.Label className="xcn-text-12 text-secondary">Question</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control as="textarea" rows={5} value={props.questionText} onChange={(e: any) => props.setQuestionText(e.target.value)} />
                                        </Col>
                                    </Row>
                                }
                                {toggleQuestion === false &&
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label className="xcn-text-12 text-secondary">Upload New Question Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={props.handleMultiMediaUpload} />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        }
                    </Row>
                </Card.Body>
            </Card>

            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={12}>

                            <Form.Label className="xcn-text-12 text-secondary">Question Video</Form.Label><br />
                            {props?.allowed?.video && encVideo ?
                                <video width="320" height="240" controls>
                                    <source type="video/mp4" src={encVideo} />
                                    <source type="video/ogg" src={encVideo} />
                                </video> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Video File Uploaded</span>
                                </Alert>}
                            {props.isEdit &&
                                <div className="mt-3">
                                    <hr />
                                    <Form.Label className="xcn-text-12 text-secondary">Upload New Question Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    <Form.Control className="w-50" type="file" name="video" accept='.mp4' onChange={props.handleMultiMediaUpload} />
                                </div>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Audio</Form.Label><br />
                            {props?.allowed?.audio && encAudio ?
                                <AudioPlayer
                                    autoPlay
                                    src={encAudio}
                                    onPlay={e => console.log("onPlay")}
                                    muted
                                /> : <Alert variant="danger">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    <span className="ms-3">No Audio File Uploaded</span>
                                </Alert>}
                        </Col>
                        {props.isEdit &&
                            <div className="mt-3">
                                <hr />
                                <Form.Label className="xcn-text-12 text-secondary">Upload New Question Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                <Form.Control className="w-50" type="file" name="audio" accept="audio" onChange={props.handleMultiMediaUpload} />
                            </div>
                        }
                    </Row>
                </Card.Body>
            </Card>


            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Attachments</Form.Label><br />
                            {props?.allowed?.attachments && props?.allowed?.attachments?.length > 0 ? props?.allowed?.attachments?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <div>
                                            <p className="text-muted xcn-text-10 fw-bold" >Image {index + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncAttachmentsImage(data); setShowPreviewModal(true) }} />
                                                {/* <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => handleDelete(index)} /></span> */}
                                            </p>
                                        </div>
                                    </>
                                )
                            }) : <Alert variant="danger">
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                <span className="ms-3">No Attachments File Uploaded</span>
                            </Alert>}

                            {props.isEdit &&
                                <div className="mt-3">
                                    <hr />
                                    <Form.Label className="xcn-text-12 text-secondary">Upload New Question Image Attachemnts</Form.Label>
                                    <Form.Control className="w-50" type="file" name="image" accept="image/png, image/jpeg" onChange={props.handleQuestionAttachements} />
                                    <Col md={6}>
                                        <Form.Label className="xcn-text-12 text-secondary mt-2">Uploads</Form.Label>
                                        {props.multimediaAttachmentData?.length > 0 ? props.multimediaAttachmentData?.map((attachment: any, index: number) => {
                                            return (
                                                <div>
                                                    <p className="text-muted xcn-text-10 fw-bold" >Image {index + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncAttachmentsImage(attachment); setShowPreviewModal(true) }} />
                                                        <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => handleDelete(index)} /></span>
                                                    </p>
                                                </div>
                                            )
                                        }) : <p className="xcn-text-12 text-secondary">No attachents uploaded</p>}
                                        <PreviewModal
                                            type="image"
                                            show={showPreviewModal}
                                            onHide={() => setShowPreviewModal(false)}
                                            image={attachmentsData}
                                        />
                                    </Col>
                                </div>
                            }

                            <PreviewModal
                                type="image"
                                show={showPreviewModal}
                                onHide={() => setShowPreviewModal(false)}
                                image={attachmentsData}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}