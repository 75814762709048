import axios, { AxiosRequestConfig, Method } from "axios";

export default async function makeUploadRequest(url: string, method: Method, formData?: any) {
    let requestConfig: AxiosRequestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VER}`,
        url: url,
        method: method,
        headers: {
            Authorization: sessionStorage.getItem("bnkauthkey") || "",
            "Content-Type": "multipart/form-data",
        },
        data: formData
    };
    
    return await axios.request(requestConfig);
}
