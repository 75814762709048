import { faAngleLeft, faHome, faPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { IRouter, routes } from '../../Routes/routes';
import "./Sidebar.css"
import { useContext } from 'react';
import { RolesContext } from '../../Routes/Private.context';

export default function Sidebar() {
    let navigate = useNavigate();
    const location = useLocation()

    const { roles, getUserRoles } = useContext(RolesContext);
    const { params } = useParams();


    const checkIfRouteAllowed = (path: string) => {
        const allowed = roles?.find(((data: any) => data.role === path && data.allowed === true))
        return allowed ? true : false;


    }


    return (
        <>
            <Nav defaultActiveKey="0" className="flex-column xcn-dashboard-sub-nav bg-primary flex-nowrap overflow-auto">

                <div className="d-flex justify-content-center align-items-center mb-3">
                    <div className="text-white mt-4 xcn-text-12">
                        {/* 
                    {
                        backButton ?
                            <span onClick={()=>navigate("/dash")}>
                                <FontAwesomeIcon icon={faHome} className="me-2 text-white xcn-link-pointer" />
                            </span> : ""
                    } */}
                        <span onClick={() => navigate("/dash")}>
                            <FontAwesomeIcon icon={faHome} className="me-2 xcn-link-pointer" />
                        </span>
                        NIXBNK
                    </div>
                </div>




                {
                    routes.map((data: IRouter, index: number) => {
                        if (!checkIfRouteAllowed(data.role)) {
                            return <>
                            </>
                        }
                        if (data.navShow == true) {
                            return (
                                <div className={`xcn-sidebar-links subNavLink xcn-link-pointer text-diselected-primary`
                                    + (
                                        // check for exact match
                                        location.pathname.split("/").find((path: string) => path === data.route) ? "xcn-nav-selected" : ""
                                    )
                                }
                                    onClick={() => {
                                        navigate("/dash/" + data.route);
                                    }}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={data.icon} />
                                    </div>
                                    <div className="xcn-text-10 d-flex justify-content-center align-items-center mt-1">
                                        {data.name}
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                {/* <hr className="text-white" /> */}
                <div className="text-center bg-primary py-3 side-logout">
                    <Link className="text-white xcn-text-12" to="/logout">
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faSignOutAlt} className="text-white" />
                        </div>
                        Logout
                    </Link>
                </div>
            </Nav>

        </>

    )
}