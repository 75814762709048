import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import ViewService from "../../../services/view.service";
import BasicModal from '../../../components/Modals/Basic.modal';

interface IViewVerificationIssues {
    issues: any[]
    reloadQuestionData: any
}

export default function ViewVerificationIssues(props: IViewVerificationIssues) {

    const [verificationId, setVerificationId] = useState<any>(undefined);

    const sendForReverification = async () => {
        await ViewService.sendForReverification(verificationId).then((res) => {
            if (res.status === 200) {
                toast.success("Send for Verification")
                setVerificationId(undefined);
                props.reloadQuestionData();
            }
        }).catch(e => {
            console.error(e);
            toast.error("Something went wrong while sending for verification");
        })
    }

    const handleReverificationRequest = (verificationId:string) => {    
        setVerificationId(verificationId);
    }


    return (
        <>
            <BasicModal
                show={verificationId}
                setShow={() => setVerificationId(undefined)}
                body={<>
                    <div className="bg-light rounded p-2 xcn-text-12 fst-italic text-danger">
                        Are you sure you want to send this question for RE-VERIFICATION. Make sure you have <br/>1. Made the appropriate changes <br/>2. Saved the question. <br/><br/>Unsaved Data will be lost!
                    </div>
                    <div>
                        <Button size="sm" className="mt-3 fw-bold xcn-text-12" onClick={sendForReverification}>Yes, Continue</Button>
                    </div>

                </>}
                heading="Alert!"
            />
            <Card className="reg-card box-shadow">
                <Card.Body>
                    <h6 className="mt-2 fw-bold text-secondary">Verification Issues</h6>
                    {props.issues.map((issue: any, index: number) => {
                        return (<>
                            <div className="border border-1 border-muted my-3 rounded p-2 ">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="fw-bold xcn-text-10 text-muted" >Id: {issue._id}</div>
                                    <div>{issue.isSolved ? <div className="text-success xcn-text-10 fw-bold">Solved <FontAwesomeIcon icon={faCheckCircle} /></div> : <div className="text-danger xcn-text-10 fw-bold">Unresolved <FontAwesomeIcon icon={faTimesCircle} /></div>}</div>
                                </div>
                                <div className="mt-1 fw-bold xcn-text-12">
                                    Remarks
                                    <div className="text-muted fw-normal fst-italic bg-light rounded p-2 xcn-text-10">
                                        {issue.remarks}
                                    </div>
                                    <div>
                                        {issue.isEdited != true && <>
                                            <Button variant="primary" className="mt-1" size="sm" onClick={()=>handleReverificationRequest(issue._id)}>Send for Re-verification</Button>
                                        </>}
                                    </div>
                                </div>
                            </div>

                        </>)
                    })}

                </Card.Body>
            </Card>
        </>
    )
}