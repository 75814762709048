import { urls } from "../api/api.urls";
import makeRequest, { makeParams } from "../api/make.request";

export default class QAService {
    static async getAllSubjects() {
        return await makeRequest(urls.profile.getAllSubjects, "GET");
    }

    static async getAllNotVerifiedQuestionsForSubject(subjectId: string) {
        return await makeRequest(urls.qa.getAllNotVerifiedQuestionsForSubject + "/" + subjectId, "GET");
    }

    static async getNotVerifiedQuestionsByCompany(subjectId: any) {
        return await makeRequest(urls.qa.getNotVerifiedQuestionsByCompany + "/" + subjectId, "GET");
    }

    static async getQuestionbyQuestionId(questionId: any) {
        return await makeRequest(urls.qa.getQuestionByQuestionId + "/" + questionId, "GET");
    }

    static async getEncMultimedia(url: any) {
        return await makeRequest(urls.qa.getEncMultimedia + "?url=" + url, "GET");
    }

    static async giveVerificationIssue(payload: any, questionid: any) {
        return await makeRequest(urls.qa.giveVerificationIssue + "/" + questionid, "PUT", payload);
    }

    static async updateVerificationIssue(payload: any, questionid: any) {
        return await makeRequest(urls.qa.updateVerificationIssue + "/" + questionid, "PUT", payload);
    }

    static async setVerificationIssue(payload: any, questionid: any) {
        return await makeRequest(urls.qa.setVerificationOfQuestion + "/" + questionid, "PUT", payload);
    }

    static async getNextQuestion(questionid: any, subjectId: any) {
        return await makeRequest(urls.qa.getNextQuestions + "/" + subjectId + "/" + questionid, "GET");
    }

    static async getNextAndPrevQuestionByQuestionId(questionId: any, moveValue: string, status: any, questionType: any) {
        const params = makeParams([
            {
                index: "value",
                value: moveValue
            },
            {
                index: "status",
                value: status
            },
            {
                index: "questionType",
                value: questionType
            },
        ])
        return await makeRequest(urls.qa.getNextAndPrevQuestionByQuestionId + "/" + questionId + params, "GET")
    }

    static async setIsEditedFalse(remarksId: any, payload: any) {
        return await makeRequest(urls.qa.setIsEditedFalse + "/" + remarksId, "PUT", payload);
    }

    static async setIsSolvedTrue(remarksId: any) {
        return await makeRequest(urls.qa.setIsSolvedTrue + "/" + remarksId, "PUT");
    }

    static async getAllTopicsBySubject(subjectId: any) {
        return await makeRequest(urls.qa.getAllTopicsBySubjectId + "/" + subjectId, "GET");
    }

    static async getAllQuestions(subjectId: any, topicId: any, status: any, pageNumber: number, pageSize: number) {
        const params = makeParams([
            {
                index: 'subject',
                value: subjectId,
            },
            {
                index: 'topic',
                value: topicId,
            },
            {
                index: 'status',
                value: status,
            },
            {
                index: 'pageNumber',
                value: pageNumber,
            },
            {
                index: 'pageSize',
                value: pageSize,
            },
        ]);
        return await makeRequest(urls.qa.getAllQuestions + params, "GET")
    }

    static async updateQuestionDifficulty(questionId: any, payload: any) {
        return await makeRequest(urls.qa.updateQuestionDifficulty + "/" + questionId, "PUT", payload)
    }
}