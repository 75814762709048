import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

interface ITopicInsert {
    name: string
}

export default class SubjectService {

    static async insert(name: string, topics: ITopicInsert[]) {
        return makeRequest(urls.subjects.insert, "post", {
            name,
            topics
        });
    }

    static async update(id: string, update: any) {
        return makeRequest(urls.subjects.update+"/"+id, "put", update);
    }

    static async getAllSubjects(searchText: any, pageNumber: number, perPage: number) {
        return await makeRequest(urls.subjects.getCompanySubjects + "?searchText=" + searchText + "&pageNumber=" + pageNumber + "&perPage=" + perPage, "GET");
    }

    static async addSubject(subject_name: any) {
        return makeRequest(urls.subjects.insert, "POST", { name: subject_name });
    }

    static async getSubjectById(subjectId: string) {
        return await makeRequest(urls.subjects.getSubjectById + "/" + subjectId, "GET");
    }

    static async updateSubject(subjectId: string, payload: any) {
        return await makeRequest(urls.subjects.updateSubject + "/" + subjectId, "PUT", payload);
    }

    static async insertTopic(topicId: any, payload: any) {
        return await makeRequest(urls.subjects.insertTopic + "/" + topicId, "POST", payload);
    }

    static async updateTopic(topicId: any, payload: any) {
        return await makeRequest(urls.subjects.updateTopic + "/" + topicId, "PUT", payload);
    }
    
}