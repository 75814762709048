import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class DownloadQpService {
    static async getAllQPForUser() {
        return await makeRequest(urls.download.getAllQPForUser, "get");
    }

    static async getQuestionPaperZip(questionPaperId: string){
        return await makeRequest(urls.download.getQuestionPaperZip+"/"+questionPaperId, "get")
    }
}