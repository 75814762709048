import React, { useEffect, useState } from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Form, Row, Table, Modal, FloatingLabel, Alert } from "react-bootstrap";
import toast from 'react-hot-toast';
import QP_GenerationService from '../../services/qp_generation.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import { useLoader } from "../../../components/Loader/Loader";

interface IConfirmationModal {
    show: boolean,
    setShow: any,
    reqQuestions: any,
}

export default function ConfirmationModal(props: IConfirmationModal) {
    const [selfUploaded, setSelfUploaded] = useState<boolean>(true);
    const [examId, setExamId] = useState<string>();
    const [shiftId, setShiftId] = useState<string>();

    const [error, setError] = useState<string>();

    const [createQPModal, setCreateQpModal] = useState<boolean>(false);


    const checkAvailability = async () => {
        await QP_GenerationService.createQuestionPaper({
            "selfUploaded": selfUploaded,
            "test": true,
            "shiftId": shiftId,
            "examId": examId,
            "reqQuestions": props.reqQuestions().map((ques_data: any) => {
                return {
                    "number_of_questions": ques_data.number_of_questions,
                    "difficulty_level": ques_data.difficulty_level,
                    "topicId": ques_data.topicId,
                    "subjectId": ques_data.subjectId,
                    "questionType": ques_data.questionType,
                    "language": ques_data.language,
                    "optionType": ques_data.optionType
                }
            })
        }).then(res => {
            if (res.status === 200) {
                console.log(res.data);
                setError(undefined)
                setCreateQpModal(true)
            }
        }).catch(e => {
            setError(e.response.data);
            console.error(e.response.data);
        })
    }

    const createQuestionPaper = async () => {
        await QP_GenerationService.createQuestionPaper({
            "selfUploaded": selfUploaded,
            "test": false,
            "shiftId": shiftId,
            "examId": examId,
            "reqQuestions": props.reqQuestions().map((ques_data: any) => {
                return {
                    "number_of_questions": ques_data.number_of_questions,
                    "difficulty_level": ques_data.difficulty_level,
                    "topicId": ques_data.topicId,
                    "subjectId": ques_data.subjectId,
                    "questionType": ques_data.questionType,
                    "language": ques_data.language,
                    "optionType": ques_data.optionType
                }
            })
        }).then(res => {
            if (res.status === 200) {
                console.log(res.data);
                setError(undefined)
                props.setShow(false)
                toast.success("Paper Created Sucessfully")
            }
        }).catch(e => {
            setError(e.response.data);
            console.error(e.response.data);
        })
    }


    const renderModalBody = () => {
        if (createQPModal) {
            return (
                <div>
                    {error && <Alert variant='danger'>
                        {error}
                    </Alert>}
                    {<Alert variant='warning'>
                        This operation cannot be reversed
                    </Alert>}
                    <Button variant="primary" className="mt-2" onClick={() => { createQuestionPaper() }}>
                    I Understand and wish to Create Question Paper
                    </Button>
                </div>
            )
        }
        else {
            return (
                <div>
                    {error && <Alert variant='danger'>
                        {error}
                    </Alert>}

                    <Form.Check checked={selfUploaded} label="Use ONLY Company Uploaded Questions" onChange={(e) => {
                        setSelfUploaded(e.target.checked)
                    }} />
                    <FloatingLabel
                        controlId="examId"
                        label="Exam Id"
                        className="mb-3"
                    >
                        <Form.Control type="text" onChange={(e) => { setExamId(e.target.value) }} />
                    </FloatingLabel>
                    <FloatingLabel controlId="shiftId" label="Shift Id">
                        <Form.Control type="text" onChange={(e) => { setShiftId(e.target.value) }} />
                    </FloatingLabel>
                    <Button variant="primary" className="mt-2" onClick={() => { checkAvailability() }}>
                        Check Availability
                    </Button>
                </div>
            )
        }
    }


    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Question Paper Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderModalBody()}
                </Modal.Body>
            </Modal>
        </>
    );
}
