import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyARecord } from 'dns';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MdFormatListBulleted } from 'react-icons/md';
import Select from "react-select"
import { AutoUploadService } from '../../services/autoUpload.service';
import PublicService from '../../services/public.service';
import UserProfileService from '../../services/userprofile.service';

const UploadPdfForm = () => {

    const pdf = require("../../assets/sample_doc.pdf");

    const [languages, setLanguages] = useState<any[]>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [difficultyLevel, setDifficultyLevel] = useState<any[]>();
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState<any>();
    const [questionTypes, setQuestionTypes] = useState<any[]>();
    const [subjects, setSubjects] = useState<any[]>();
    const [selectedQuestionSubject, setSelectedQuestionSubject] = useState<any>();
    const [optionCount, setOptionCount] = useState<any>();
    const [uploadFilesData, setUpoadFilesData] = useState<any>();
    const [selectOptionType, setSelectOptionType] = useState<any>();
    const [selectOptionLabel, setSelectedOptionLabel] = useState<any>();

    const [loading, setLoading] = useState<boolean>(false);
    const [examCategory, setExamCategory] = useState<any>();
    const [selectExamCategory, setSelectExamCategory] = useState<any>();


    const getAllQuestionTypes = async () => {
        await UserProfileService.getAllQuestionTypes().then(res => {
            if (res.status === 200) {
                const questiontypes = res.data.map((data: string) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setQuestionTypes(questiontypes)
            }
        })
    }

    const getExamCategory = async () => {
        await PublicService.getExamCategory().then(res => {
            if (res.status === 200) {
                const category = Object.values(res.data).map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id
                    }
                })
                setExamCategory(category);
            }
        })
    }



    const getAllSubjects = async () => {
        await UserProfileService.getAllSubjects().then(res => {
            if (res.status === 200) {
                const sub = res.data.map((data: any) => {
                    return {
                        options: data.topics.map((topic: any) => {
                            return {
                                value: topic._id,
                                subjectId: data._id,
                                label: topic.name
                            }
                        }),
                        label: data.name
                    }
                })
                setSubjects(sub);
            }
        }).catch(e => {
            console.error(e);
        })
    }


    const getLanguage = async () => {
        await PublicService.getLanguage().then(res => {
            if (res.status === 200) {
                const language = Object.values(res.data).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setLanguages(language);
            }
        })
    }

    const getDifficultyLevel = () => {
        let data = []
        for (let i = 1; i <= 10; i++) {
            data.push({
                label: i,
                value: i
            })
        }
        setDifficultyLevel(data);
    }

    const optionTypeData = [
        {
            value: "bullet",
            label: "Bullet"
        },
        {
            value: "checkbox",
            label: "Checkbox"
        }
    ]

    const optionLabelData = [
        {
            value: "alphabets",
            label: "Alphabets"
        },
        {
            value: "numeric",
            label: "Numeric"
        }
    ]

    const handleUploadFiles = (e: any) => {
        setUpoadFilesData(e.target.files[0])
    }

    const handleUploadSubmission = async () => {
        setLoading(true)

        if (!uploadFilesData || !selectedQuestionSubject.value || !selectedQuestionSubject.subjectId || !selectOptionType || !selectedLanguage || !selectOptionLabel || !optionCount || !selectedDifficultyLevel) {
            setLoading(false)
            toast.error("All Fields are Required!")
            return false;
        }

        let form_data = new FormData;

        form_data.append("file", uploadFilesData);
        form_data.append("topicId", selectedQuestionSubject.value);
        form_data.append("subjectId", selectedQuestionSubject.subjectId);
        form_data.append("optionType", selectOptionType);
        form_data.append("language", selectedLanguage);
        form_data.append("option_label", selectOptionLabel);
        form_data.append("option_count", optionCount);
        form_data.append("difficulty", selectedDifficultyLevel);
        form_data.append("examCategory", selectExamCategory.value);

        await AutoUploadService.uploadPdf(form_data).then((res) => {
            if (res.status === 200) {
                toast.success("Data uploaded successfully");
                setLoading(false)
            }
        }).catch(err => {
            toast.error(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllQuestionTypes()
        getAllSubjects()
        getLanguage()
        getDifficultyLevel()
        getExamCategory()
    }, [])
    return (
        <>
            <h5>Auto Upload Questions</h5>
            <a href={pdf} download className="">Download Sample File <FontAwesomeIcon icon={faDownload} /></a>
            <Card className="reg-card box-shadow mt-3">
                <Card.Body>
                    <Row>
                        <Col md={4} >
                            <Form.Label className="xcn-text-12 text-secondary">Question Subject & Topic</Form.Label>
                            <Select
                                options={subjects}
                                onChange={e => setSelectedQuestionSubject(e)}
                                isSearchable
                            />
                        </Col>

                        <Col md={4} >
                            <Form.Label className="xcn-text-12 text-secondary">Select Language</Form.Label>
                            <Select
                                options={languages}
                                onChange={(e: any) => setSelectedLanguage(e.value)}
                                isSearchable
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Exam Category</Form.Label>
                            <Select
                                options={examCategory}
                                onChange={e => setSelectExamCategory(e)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={4}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Question Difficulty Level</Form.Label>
                            <Select
                                options={difficultyLevel}
                                onChange={(e: any) => setSelectedDifficultyLevel(e.value)}
                            />
                        </Col>
                        <Col md={4} >
                            <Form.Label className="xcn-text-12 text-secondary">Select Option Type</Form.Label>
                            <Select
                                options={optionTypeData}
                                onChange={(e: any) => setSelectOptionType(e.value)}
                                isSearchable
                            />
                        </Col>
                        <Col md={4} >
                            <Form.Label className="xcn-text-12 text-secondary">Select Option Label</Form.Label>
                            <Select
                                options={optionLabelData}
                                onChange={(e: any) => setSelectedOptionLabel(e.value)}
                                isSearchable
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col md={6} >
                            <Form.Label className="xcn-text-12 text-secondary mt-2">Select Option Count</Form.Label>
                            <Form.Control type="number" name="option_count" onChange={(e: any) => setOptionCount(e.target.value)} />
                        </Col>
                        <Col md={6}>
                            <Form.Label className="xcn-text-12 text-secondary mt-2">Select Pdf</Form.Label>
                            <Form.Control type="file" name="file" accept=".pdf" onChange={handleUploadFiles} />
                            {/* <Button onClick={handleQuestionAttachements}>Upload</Button> */}
                        </Col>
                    </Row>
                    <Button onClick={handleUploadSubmission} className="mt-2" disabled={loading}>Upload</Button>
                </Card.Body>
            </Card>
        </>
    )
}

export default UploadPdfForm