import { useEffect, useState } from "react";
import { IMedia } from "../helpher/interfaces/Comprehension.interface";
import { MediaRenderer } from "../helpher/MediaRender.helper";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Alert, Form, Card } from "react-bootstrap"
import QAService from "../../services/qa.service";
import AudioPlayer from 'react-h5-audio-player';


export const QaTheory = ({ theory }: { theory: IMedia }) => {
    const [encData, setEncData] = useState<string>('');
    const [encVideo, setEncVideo] = useState<string>('');
    const [encAudio, setEncAudio] = useState<string>('');
    const [encDoc, setEncDoc] = useState<string>('');

    const getEncTheoryMedia = async (type: 'image' | 'video' | 'audio' | 'doc', setter: React.Dispatch<React.SetStateAction<string>>) => {
        const mediaUrl = theory[type];
        if (mediaUrl) {
            try {
                const res = await QAService.getEncMultimedia(mediaUrl);
                if (res.status === 200) {
                    setter(type === 'image' ? res.data : res.data.toString());
                }
            } catch (error) {
                console.error(`Failed to fetch ${type}:`, error);
            }
        }
    };

    useEffect(() => {
        getEncTheoryMedia('image', setEncData);
        getEncTheoryMedia('video', setEncVideo);
        getEncTheoryMedia('audio', setEncAudio);
        getEncTheoryMedia('doc', setEncDoc);
    }, [theory]);

    return (
        <div className="mt-3">
            <h5 className="mt-3 mb-2">2. Theory</h5>
            <Row className="mb-2">
                <Col>
                    <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Text</Form.Label>
                                <Form.Control as="textarea" readOnly rows={5} defaultValue={theory.text} />
                            </Form.Group>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            {theory.image && encData &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Image</Form.Label>
                            <div className="mb-4">
                                <MediaRenderer type='image' data={encData} />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            }
            {theory.audio && encAudio &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Audio</Form.Label>
                            <AudioPlayer
                                autoPlay
                                src={encAudio}
                                onPlay={() => console.log("onPlay")}
                                muted
                            />
                        </div>
                    </Card.Body>
                </Card>

            }

            {theory.video && encVideo &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Video</Form.Label>
                            <div>
                                <video width="320" height="240" controls>
                                    <source type="video/mp4" src={encVideo} />
                                    <source type="video/ogg" src={encVideo} />
                                </video>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

            }

            {theory.doc && encDoc &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-1">
                            <Form.Label className="xcn-text-12 text-secondary">Pdf</Form.Label>
                            <embed src={encDoc} type="application/pdf" width="100%" height="400px" />
                        </div>
                    </Card.Body>
                </Card>
            }
        </div>
    );
};