import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Card, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


interface IQuestionNotVerified {
    data: any;
    loading: boolean
}

const QuestionNotVerified = ({ data, loading }: IQuestionNotVerified) => {
    const navigate = useNavigate()
    return (
        <>
            <Card className="reg-card box-shadow">
                <Card.Body>
                    <Table striped hover size="sm" className="xcn-text-12">
                        <thead>
                            <tr className='text-secondary'>
                                <th>#</th>
                                <th>Internal Id</th>
                                <th>Question Type</th>
                                <th>Language</th>
                                <th>Added By</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <tr><td colSpan={5} className='text-center'> <Spinner animation="border" variant="primary" size="sm" /> Loading...</td></tr> :
                                    data && data?.length > 0 ? data?.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td className='fw-bold xcn-text-10'>{item?.questionData?._id}</td>
                                                    <td className="fw-bold">{item?.questionData?.questionType?.toUpperCase()}</td>
                                                    <td className="fw-bold text-secondary">{item?.questionLanguage[0]?.language?.toUpperCase()}</td>
                                                    <td>{item?.questionData?.createdBy?.username || "--"}</td>
                                                    <td><FontAwesomeIcon className="text-primary" icon={faEye} onClick={() => { navigate(item?.questionLanguage[0]?._id) }} /></td>
                                                </tr>
                                            </>
                                        )
                                    }) : "No data available"}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}

export default QuestionNotVerified