import AudioPlayer from 'react-h5-audio-player';


export const MediaRenderer = ({ type, data }: { type: 'image' | 'video' | 'audio' | 'doc'; data: string }) => {
    switch (type) {
        case 'image':
            return (
                <div style={{ height: 400, width: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={data && data.split(";base64,")[1] ? data : "data:image/jpeg;base64," + data}
                        style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                    />
                </div>
            );
        case 'video':
            return (
                <div>
                    <video width="320" height="240" controls>
                        <source type="video/mp4" src={data} />
                        <source type="video/ogg" src={data} />
                    </video>
                </div>
            );
        case 'audio':
            return (
                <AudioPlayer
                    autoPlay
                    src={data}
                    onPlay={() => console.log("onPlay")}
                    muted
                />
            );
        case 'doc':
            return <embed src={data} type="application/pdf" width="100%" height="400px" />;
        default:
            return null;
    }
};