import AudioPlayer from 'react-h5-audio-player';
import { Card, Row, Col, Form, Alert } from 'react-bootstrap'
import UploadService from '../../services/upload.service'
import toast from 'react-hot-toast'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslatorService } from '../../services/translator.service';
import { useEffect, useState } from 'react';

interface IQuestionExplanation {
    data: any
    setData: any
}

export default function AddQuestionExplanation({ data, setData }: IQuestionExplanation) {

    const [encImage, setEncImage] = useState<string>("");
    const [encVideo, setEncVideo] = useState<string>("");
    const [encAudio, setEncAudio] = useState<string>("");

    async function getEncImage() {
        if(!data?.image) return;
        await TranslatorService.getEncMultimedia(data?.image).then((res => {
            if (res.status === 200) {
                setEncImage(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    async function getEncVideo() {
        if(!data?.video) return;
        await TranslatorService.getEncMultimedia(data?.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    async function getEncAudio() {
        if(!data?.audio) return;
        await TranslatorService.getEncMultimedia(data?.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    const handleMultiMediaUpload = async (e: any) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);
        await UploadService.uploadMultimedia(form_data)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                    setData({ ...data, [e.target.name]: res.data });
                }
            }).catch(e => {
                toast.error("Something went wrong when uploading Multimedia");
            })
    }

    useEffect(() => {
        getEncImage();
        getEncVideo();
        getEncAudio();
    }, [data]);
        
    return (
        <>
            <Card className="reg-card box-shadow mt-3 mb-4">
                <Card.Body>
                    <Form.Group>
                        {
                            data?.image &&
                            <div className="mt-2">
                                <img src={encImage} width={100} height={100} />
                            </div>
                        }
                        <Form.Label className="xcn-text-12 text-secondary">Explanation Image</Form.Label>
                        <Form.Control type="file" accept='image/*' onChange={(e: any) => handleMultiMediaUpload(e)} name="image" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="xcn-text-12 text-secondary">Explanation Text</Form.Label>
                        <Form.Control type="text" onChange={(e: any) => setData({ ...data, text: e.target.value })} value={data?.text} />
                    </Form.Group>

                    <Form.Group>
                        {
                            data?.video &&
                            <div className="mt-2">
                                <video width={100} height={100} controls src={encVideo} />
                            </div>
                        }
                        <Form.Label className="xcn-text-12 text-secondary">Explanation Video</Form.Label>
                        <Form.Control type="file" accept='video/*' onChange={(e: any) => handleMultiMediaUpload(e)} name="video" />
                    </Form.Group>

                    <Form.Group>
                        {
                            data?.audio &&
                            <div className="mt-2">
                                <AudioPlayer src={encAudio} muted />
                            </div>
                        }
                        <Form.Label className="xcn-text-12 text-secondary">Explanation Audio</Form.Label>
                        <Form.Control type="file" accept='audio/*' onChange={(e: any) => handleMultiMediaUpload(e)} name="audio" />
                    </Form.Group>
                </Card.Body>
            </Card>
        </>
    )
}

interface IProps {
    data: any
}

export function ViewQuestionExplanation({ data }: IProps) {
    const [encImage, setEncImage] = useState<string>("");
    const [encVideo, setEncVideo] = useState<string>("");
    const [encAudio, setEncAudio] = useState<string>("");

    async function getEncImage() {
        if(!data?.image) return;
        await TranslatorService.getEncMultimedia(data?.image).then((res => {
            if (res.status === 200) {
                setEncImage(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    async function getEncVideo() {
        if(!data?.video) return;
        await TranslatorService.getEncMultimedia(data?.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    async function getEncAudio() {
        if(!data?.audio) return;
        await TranslatorService.getEncMultimedia(data?.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res?.data.toString());
            }
        }))
        .catch(e => {
            console.error(e)
        })
    }

    useEffect(() => {
        getEncImage();
        getEncVideo();
        getEncAudio();
    }, [data]);

    return (
        <Card className="reg-card box-shadow mt-3 mb-4">
            <h6 className='p-2 mb-0'>Explanation</h6>
            <Card.Body>
                {
                    data?.image ?
                        <div className='d-flex flex-column'>
                            <Form.Label className="xcn-text-12 text-secondary">Explanation Image</Form.Label>
                            <img src={encImage} width={100} height={100} />
                        </div >
                        : <Alert variant="danger">
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span className="ms-3">No Image File for explanation </span>
                        </Alert>
                }

                <Form.Group>
                    <Form.Label className="xcn-text-12 text-secondary mb-0">Explanation Text</Form.Label>
                    <br />
                    <Form.Text>
                        {data?.text}
                    </Form.Text>
                </Form.Group>
                {
                    data?.video ?
                        <div className='d-flex flex-column'>
                            <Form.Label className="xcn-text-12 text-secondary">Explanation Video</Form.Label>
                            <video src={encVideo} width={100} height={100} />
                        </div >
                        : <Alert variant="danger">
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span className="ms-3">No Video File for explanation </span>
                        </Alert>
                }

                {
                    data?.audio ?
                        <div className='d-flex flex-column'>
                            <Form.Label className="xcn-text-12 text-secondary">Explanation Audio</Form.Label>
                            <AudioPlayer
                                src={encAudio}
                                showSkipControls={true}
                                showJumpControls={false}
                                onPlay={() => {
                                    console.log("onPlay");
                                }}
                                muted
                            />
                        </div >
                        : <Alert variant="danger">
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span className="ms-3">No Audio File for explanation </span>
                        </Alert>
                }
            </Card.Body>
        </Card>
    )
}