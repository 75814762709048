import { faArrowRightArrowLeft, faBook, faPlug, faUpload, faUsers, faPlus, faDownload, faVialCircleCheck, faEye, faFile, faKey, faLanguage, faList } from "@fortawesome/free-solid-svg-icons";
import { UserRoleEnum } from "../enums/role.enum";
import RequestsIndex from "../views/Requests/Requests.index";
import UploadQuestionIndex from "../views/UploadQuestions/UploadQuestions.index";
import UsersIndex from "../views/Users/Users.index";
import QPGenerationIndex from "../views/QPGeneration/QPGeneration.index";
import QPDownloadIndex from "../views/QpDownload/QPDownload";
import DashboardIndex from "../views/Dashboard/Dashboard.index";
import QAIndex from "../views/QA/Qa.index";
import ViewNotVerifiedQuestion from "../views/QA/ViewNotVerifiedQuestion";
import ViewIndex from "../views/ViewQuestions/View.index";
import ViewQuestionIndex from "../views/ViewQuestions/ViewQuestion/ViewQuestion.index";
import UploadPdfIndex from "../views/UploadPdf/uploadPdf.index";
import ApiKeyIndex from "../views/ApiKey/ApiKey.index";
import View from "../views/ViewQuestions/View";
import UserViewNotVerifiedQuestion from "../views/ViewQuestions/UserViewNotVerifiedQuestion";
import TranslatorIndex from "../views/Translator/Translator.index";
import TranslatorById from "../views/Translator/TranlatorById";
import ExamCategory from "../views/ExamCategory/ExamCategory.index";
import UpdatedSubjectIndex from "../views/Subjects/Subject.index";
import SubjectById from "../views/Subjects/SubjectById";


export interface IRouter {
    name: string;
    route: any;
    role: UserRoleEnum;
    element: any;
    navShow: boolean;
    icon: any
}




export const routes: IRouter[] = [
    {
        role: UserRoleEnum.DASHBOARD,
        route: UserRoleEnum.DASHBOARD,
        name: "Dashboard",
        element: DashboardIndex,
        navShow: true,
        icon: faPlug
    },
    {
        role: UserRoleEnum.VIEW,
        route: UserRoleEnum.VIEW,
        name: "View",
        element: ViewIndex,
        navShow: true,
        icon: faEye
    },
    {
        role: UserRoleEnum.VIEW,
        route: UserRoleEnum.VIEW + "/edit" + "/:questionId" + "/language" + "/:languageId",
        name: "",
        element: ViewQuestionIndex,
        navShow: false,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.VIEW,
        route: UserRoleEnum.VIEW + `/question` + "/:questionId" + "/:languageId",
        name: "",
        element: View,
        navShow: false,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.VIEW,
        route: UserRoleEnum.VIEW + "/question" + "/:questionId" + "/:languageId",
        name: "",
        element: UserViewNotVerifiedQuestion,
        navShow: false,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.UPLOAD,
        route: UserRoleEnum.UPLOAD,
        name: "Upload",
        element: UploadQuestionIndex,
        navShow: true,
        icon: faUpload
    },

    {
        role: UserRoleEnum.UPLOAD,
        route: "auto-upload",
        name: "Auto Upload",
        element: UploadPdfIndex,
        navShow: true,
        icon: faFile
    },
    {
        role: UserRoleEnum.DOWNLOAD,
        route: UserRoleEnum.DOWNLOAD,
        name: "Download",
        element: QPDownloadIndex,
        navShow: true,
        icon: faDownload

    },
    // {
    //     role: UserRoleEnum.SUBJECT,
    //     route: UserRoleEnum.SUBJECT,
    //     name: "Subjects",
    //     element: SubjectsIndex,
    //     navShow: true,
    //     icon: faBook
    // },

    {
        role: UserRoleEnum.QP_GENERATION,
        route: UserRoleEnum.QP_GENERATION,
        name: "Create QP",
        element: QPGenerationIndex,
        navShow: true,
        icon: faPlus
    },
    {
        role: UserRoleEnum.EXAM_CATEGORY,
        route: UserRoleEnum.EXAM_CATEGORY,
        name: "ExamCategory",
        element: ExamCategory,
        navShow: true,
        icon: faList
    },
    {
        role: UserRoleEnum.SUBJECT,
        route: UserRoleEnum.SUBJECT,
        name: "Subject",
        element: UpdatedSubjectIndex,
        navShow: true,
        icon: faBook
    },
    {
        role: UserRoleEnum.SUBJECT,
        route: UserRoleEnum.SUBJECT +"/:id",
        name: "SubjectById",
        element: SubjectById,
        navShow: false,
        icon: undefined
      },
    {
        role: UserRoleEnum.QA,
        route: UserRoleEnum.QA,
        name: "QA",
        element: QAIndex,
        navShow: true,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.QA,
        route: UserRoleEnum.QA + "/:questionId",
        name: "",
        element: ViewNotVerifiedQuestion,
        navShow: false,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.REQUESTS,
        route: UserRoleEnum.REQUESTS,
        name: "Logs",
        element: RequestsIndex,
        navShow: true,
        icon: faArrowRightArrowLeft

    },
    {
        role: UserRoleEnum.USER,
        route: UserRoleEnum.USER,
        name: "Users",
        element: UsersIndex,
        navShow: true,
        icon: faUsers
    },

    {
        role: UserRoleEnum.TRANSLATOR,
        route: UserRoleEnum.TRANSLATOR,
        name: "Translator",
        element: TranslatorIndex,
        navShow: true,
        icon: faLanguage
    },
    {
        role: UserRoleEnum.TRANSLATOR,
        route: UserRoleEnum.TRANSLATOR + "/:questionId",
        name: "",
        element: TranslatorById,
        navShow: false,
        icon: faVialCircleCheck
    },
    {
        role: UserRoleEnum.USER,
        route: 'apiKey',
        name: "API_KEY",
        element: ApiKeyIndex,
        navShow: true,
        icon: faKey
    }
]