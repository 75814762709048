import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class PublicService {
    static async userLogin(username: string, password: string, token: string) {
        return makeRequest(urls.public.userLogin + "?token=" + token, "post", {
            email: username,
            password: password
        });
    }

    static async getLanguage() {
        return makeRequest(urls.public.getLanguage, "get");
    }

    static async getExamCategory() {
        return makeRequest(urls.public.getExamCategory, "get");
    }
}