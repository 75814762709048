import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class UserService {
    static async getAllUsers() {
        return makeRequest(urls.users.getAllUsers, "get");
    }
    static async changeUserRole(userId: any, role: any, status: any) {
        return await makeRequest(urls.users.changeUserRole, "put", {
            userId,
            role,
            status
        })
    }

    static async blockUnblockUser(_id: any, active: any) {
        return makeRequest(urls.users.blockUnblockUser, "put", {
            _id, active
        })
    }

    static async userUpdate(_id: any, password: string) {
        return makeRequest(urls.users.userUpdate, "put", { _id, password })
    }

    static async register(username: string, password: string) {
        return makeRequest(urls.users.register, "post", { username, password })
    }

    static async addLanguage(id: any, payload: any) {
        return makeRequest(urls.users.addLanguage + "/" + id, "put", payload)
    }

    static async getTranslatedLanguage(id: any) {
        return makeRequest(urls.users.getTranslatedLanguage + "/" + id, "get")
    }

}