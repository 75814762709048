import React, { useState } from 'react';
import { Form, Row, Col, Button, Card, FloatingLabel } from 'react-bootstrap';
import { FaPlus, FaTrash } from "react-icons/fa";
import UploadService from '../../../services/upload.service';
import toast from 'react-hot-toast';
import Explanation from '../../../components/CustomComponent/Explanation';
interface IComprehensionQuestion {
    index: number,
    comprehensionData: any
    setComprehensionData: any
    compTheoryData: any
    setCompTheoryData: any
    compInstructionData: any
    setCompInstructionData: any
    compCorrectAnswer: any
    setCompCorrectAnswer: any
}

export default function ComprehensionQuestion(props: IComprehensionQuestion) {


    const [questionCount, setQuestionCount] = useState<number>(1);
    const [optionCount, setOptionCount] = useState<number>(1);

    const handleTheoryData = async (e: React.ChangeEvent<HTMLInputElement>, name: string, langIndex: number) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            toast.promise(
                UploadService.uploadMultimedia(formData).then((res) => {
                    if (res.status === 200) {
                        const updatedData = [...props.compTheoryData];

                        if (!updatedData[langIndex]) {
                            updatedData[langIndex] = {};
                        } else if (!updatedData[langIndex]) {
                            updatedData[langIndex] = {};
                        }

                        updatedData[langIndex][name] = res.data;

                        props.setCompTheoryData(updatedData);
                    }
                }),
                {
                    loading: 'Uploading',
                    success: 'Uploaded',
                    error: 'Something went wrong when uploading Multimedia',
                }
            );
        };
    }





    const handleAddQuestionData = async (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        i: number,
        langIndex: number
    ) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            toast.promise(
                UploadService.uploadMultimedia(formData).then((res) => {
                    if (res.status === 200) {
                        const currentTheoryText = [...props.comprehensionData];

                        if (!currentTheoryText[langIndex]) {
                            currentTheoryText[langIndex] = { questions: [] };
                        }
                        if (!currentTheoryText[langIndex].questions[i]) {
                            currentTheoryText[langIndex].questions[i] = {};
                        }
                        currentTheoryText[langIndex].questions[i][name] = res.data;

                        props.setComprehensionData(currentTheoryText);
                    }
                }),
                {
                    loading: 'Uploading',
                    success: 'Uploaded',
                    error: 'Something went wrong when uploading Multimedia',
                }
            );
        }
    };


    const handleOptionData = async (
        e: any,
        name: string,
        optionIndex: number,
        questionIndex: number,
        langIndex: number
    ) => {
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            try {
                let formData = new FormData();
                formData.append('file', file);

                const res = await UploadService.uploadMultimedia(formData);

                if (res.status === 200) {
                    const updatedData = [...props.comprehensionData];

                    if (!updatedData[langIndex]) {
                        updatedData[langIndex] = { questions: [] };
                    }
                    if (!updatedData[langIndex].questions[questionIndex]) {
                        updatedData[langIndex].questions[questionIndex] = { options: [] };
                    }
                    if (!updatedData[langIndex].questions[questionIndex].options[optionIndex]) {
                        updatedData[langIndex].questions[questionIndex].options[optionIndex] = {}
                    }
                    updatedData[langIndex].questions[questionIndex].options[optionIndex][name] = res.data;
                    props.setComprehensionData(updatedData);

                    toast.success('File uploaded successfully');
                } else {
                    toast.error(`Upload failed with status: ${res.status}`);
                }
            } catch (error) {
                console.error(error);
                toast.error('Something went wrong when uploading multimedia');
            }
        } else {
            toast.error('No file selected');
        }
    };





    const handleQuestionText = (e: any, index: number, langIndex: number) => {
        let currentQuestionText = [...props.comprehensionData];
        if (!currentQuestionText[langIndex]) {
            currentQuestionText[langIndex] = { questions: [] }
        }
        if (!currentQuestionText[langIndex].questions) {
            currentQuestionText[langIndex].questions = []
        }
        if (!currentQuestionText[langIndex].questions[index]) {
            currentQuestionText[langIndex].questions[index] = {}
        }
        currentQuestionText[langIndex].questions[index]["text"] = e.target.value
        props.setComprehensionData(currentQuestionText)
    };

    const handleTheoryText = (e: any, langIndex: number) => {
        const updatedData = [...props.compTheoryData];

        if (!updatedData[langIndex]) {
            updatedData[langIndex] = {};
        }

        updatedData[langIndex] = { ...updatedData[langIndex], text: e.target.value };

        props.setCompTheoryData(updatedData);
    };


    const handleComprehensionCorrectAnswer = (e: any, optionIndex: number, langIndex: number) => {
        const currentComprehensionQuestions = [...props.compCorrectAnswer];

        if (!currentComprehensionQuestions[langIndex]) {
            currentComprehensionQuestions[langIndex] = { answers: [] };
        }

        if (!Array.isArray(currentComprehensionQuestions[langIndex].answers)) {
            currentComprehensionQuestions[langIndex].answers = [];
        }

        if (e) {
            if (!currentComprehensionQuestions[langIndex].answers.includes(optionIndex)) {
                currentComprehensionQuestions[langIndex].answers.push(optionIndex);
            }
        } else {
            currentComprehensionQuestions[langIndex].answers = currentComprehensionQuestions[langIndex].answers.filter(
                (data: any) => data !== optionIndex
            );
        }

        props.setCompCorrectAnswer(currentComprehensionQuestions);
    };



    const handleInstructionChange = (e: any, langIndex: number) => {
        const updatedData = [...props.compInstructionData];

        if (!updatedData[langIndex]) {
            updatedData[langIndex] = {};
        }

        if (!updatedData[langIndex]) {
            updatedData[langIndex] = {};
        }

        updatedData[langIndex].text = e.target.value;

        props.setCompInstructionData(updatedData);
    }


    const handleOptionTextChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        optionIndex: number,
        langIndex: number,
        questionIndex: number
    ) => {
        const text = e.target.value;

        const updatedData = [...props.comprehensionData];

        if (!updatedData[langIndex]) {
            updatedData[langIndex] = { questions: [] };
        }

        if (!updatedData[langIndex].questions) {
            updatedData[langIndex].questions = [];
        }

        if (!updatedData[langIndex].questions[questionIndex]) {
            updatedData[langIndex].questions[questionIndex] = { options: [] };
        }

        if (!updatedData[langIndex].questions[questionIndex].options) {
            updatedData[langIndex].questions[questionIndex].options = [];
        }

        if (!updatedData[langIndex].questions[questionIndex].options[optionIndex]) {
            updatedData[langIndex].questions[questionIndex].options[optionIndex] = {};
        }
        updatedData[langIndex].questions[questionIndex].options[optionIndex][name] = text;
        props.setComprehensionData(updatedData);
    };



    console.log(props.comprehensionData, "comppres")



    return (
        <>
            <div>
                <h5 className="mt-3 mb-2">1. Instruction</h5>
                <div>
                    <Form.Group>
                        <Form.Control as="textarea" rows={5} cols={5} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInstructionChange(e, props.index)} />
                    </Form.Group>
                </div>
            </div>
            <hr />
            <div className="mt-3">
                <h5 className="mt-3 mb-2">2. Theory</h5>
                <div>
                    <Row>
                        <Col md={8}>
                            <Row className="mb-2">
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Text</Form.Label>
                                        <Form.Control as="textarea" rows={11} cols={5} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTheoryText(e, props.index)} />
                                    </Form.Group>
                                </Col>
                            </Row></Col>
                        <Col>
                            <div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTheoryData(e, "image", props.index)} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTheoryData(e, "audio", props.index)} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    <Form.Control type="file" name="video" accept="video/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTheoryData(e, "video", props.index)} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTheoryData(e, "doc", props.index)} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <hr />
            <div className="mt-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h5 className="mt-3">3. Add Questions</h5>
                    <Button size="sm" className="rounded-0 text-white" onClick={() => setQuestionCount(questionCount + 1)}>Add</Button>
                </div>

                {[...Array(questionCount)].map((_, questionIndex: number) => (
                    <Card key={questionIndex} className="px-2 mb-2" style={{ backgroundColor: "#D0D5DD40", borderRadius: 8, outline: "none", border: "none" }}>
                        <Card.Body>
                            <p className="fw-bold text-muted">Question {questionIndex + 1}</p>
                            <Row className="mb-2">
                                <Col md={8}>
                                    <Form.Group>
                                        <Form.Label>Text</Form.Label>
                                        <Form.Control as="textarea" rows={11} cols={5} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleQuestionText(e, questionIndex, props.index)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddQuestionData(e, "image", questionIndex, props.index)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddQuestionData(e, "audio", questionIndex, props.index)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control type="file" name="video" accept="video/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddQuestionData(e, "video", questionIndex, props.index)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddQuestionData(e, "doc", questionIndex, props.index)} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <h5>Options Data</h5>
                                    <Button className="d-flex align-items-center gap-2 mb-2" onClick={() => setOptionCount(optionCount + 1)}>
                                        <FaPlus />
                                        Add Options
                                    </Button>
                                </div>
                                <Col>
                                    {[...Array(optionCount)].map((_: any, index: number) => {
                                        return (
                                            <Card className="mt-2" style={{ borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <p className="fw-bold text-muted">Option {index + 1}</p>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Form.Group>
                                                                <Form.Label>Text</Form.Label>
                                                                <Form.Control as="textarea"
                                                                    rows={11}
                                                                    cols={5}
                                                                    onChange={(e: any) => handleOptionTextChange(e, "text", index, props.index, questionIndex)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionData(e, "image", index, questionIndex, props.index)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionData(e, "audio", index, questionIndex, props.index)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                <Form.Control type="file" name="video" accept="video/*" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionData(e, "video", index, questionIndex, props.index)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionData(e, "doc", index, questionIndex, props.index)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <div className="d-flex align-items-center gap-4">
                                                            <div className="fs-5">
                                                                <Form.Check
                                                                    className="text-success"
                                                                    checked={props.comprehensionData[props.index]?.answers?.includes(index)}
                                                                    onChange={(e: any) => handleComprehensionCorrectAnswer(e.target.checked, index, props.index)}
                                                                />
                                                            </div>
                                                            <FaTrash className="text-danger" onClick={() => setOptionCount(optionCount - 1)} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })}

                                </Col>
                            </Row>
                            <div className="mt-3 d-flex justify-content-end">
                                <Button className="d-flex align-items-center gap-2" variant="danger" onClick={() => setQuestionCount(questionCount - 1)} style={{ opacity: .9 }}>
                                    <FaTrash />
                                    Delete Question
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
            <hr />
            <div className="mt-3">
                <Explanation data={props.comprehensionData} setData={props.setComprehensionData} index={props.index} />
            </div>
        </>
    );
}


