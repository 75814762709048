import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import UserProfileService from '../services/userprofile.service';
import { QAContext, RolesContext } from './Private.context';

export default function PrivateRoutes() {

    const [roles, setRoles] = useState<any>([])
    const [qaData, setQaData] = useState<any>();


    const getUserRoles = async () => {
        await UserProfileService.getUserRoles().then(res => {
            if (res.status === 200) {
                setRoles(res.data)
            }
        })
    }

    useEffect(() => {
        getUserRoles()
    }, [])

    return (
        <>
            <RolesContext.Provider value={{ roles, getUserRoles }}>
                <QAContext.Provider value={{ qaData, setQaData }}>
                    <div className='d-flex'>
                        <Sidebar />
                        <div className='margin-top-4 col p-3 content-sec bg-light'>
                            <Outlet />
                        </div>
                    </div>
                </QAContext.Provider>
            </RolesContext.Provider>
        </>
    )
}