import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class UserProfileService {
    static async getUserRoles(){
        return makeRequest(urls.profile.getUserRoles, "get");
    }

    static async getAllQuestionTypes(){
        return makeRequest(urls.profile.getAllQuestiontypes, "get");
    }

    static async getAllSubjects() {
        return makeRequest(urls.profile.getAllSubjects, "get");
    }
}