import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Card, FloatingLabel, Form, FormCheck, Spinner, Table } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import BasicModal from '../../components/Modals/Basic.modal';
import ApiKeyService from '../../services/apiKey.service'

const ApiKeyIndex = () => {

    const [apiKeys, setApiKeys] = useState([]);
    const [createApiKeyModal, setcreateApiKeyModal] = useState<boolean>(false);
    const [newApiKey, setNewApiKey] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const apikeysOfCompany = async () => {
        setLoading(true);
        await ApiKeyService.apikeysOfCompany().then(
            (res: any) => setApiKeys(res.data)
        )
        .catch(err => {
            console.error(err);
            toast.error("Something went wrong")
        })
        .finally(() => setLoading(false))
    }

    const addApikey = async () => {
        await ApiKeyService.createApiKey(newApiKey).then((res: any) => {
            const toastId = toast.loading("processing....");
            if (res?.status === 200){ 
                toast.success("New API_KEY created", { id: toastId })
                setcreateApiKeyModal(false)
                apikeysOfCompany();
            }
            else toast.error("Something went wrong", { id: toastId })
        })
    }

    const toggleApiKey = async (id: any) => {
        await ApiKeyService.toggleApiKey(id).then((res: any) => {
            if (res?.status === 200) {
                toast.success(res.data)
                apikeysOfCompany()
            }
            else toast.error(res.data)
        })
            .catch(err => {
                console.error(err);
                toast.error("Something went wrong")
            })
    }


    useEffect(() => {
        apikeysOfCompany()
    }, [])

    return (
        <>


            <BasicModal
                show={createApiKeyModal}
                setShow={setcreateApiKeyModal}
                heading={<b>Add Api Key</b>}
                body={<>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Assign this Api Key to"
                        className="mb-3"
                    >
                        <Form.Control type="text" onChange={(e: any) => setNewApiKey({ assignedTo: e.target.value })} />
                    </FloatingLabel>
                    <Button variant="primary" className='mt-2' size="sm" onClick={addApikey}> Add Api Key </Button>
                </>
                }
            />

            <div className="d-flex justify-content-between align-items-center mt-3">
                <h4>
                    Api keys
                </h4>
                <Button variant="primary" size="sm" onClick={() => setcreateApiKeyModal(true)}>
                    Add Api Key
                </Button>
            </div>

            <div className="mt-2">
                <Card className="reg-card bg-white p-3">
                    <Table hover responsive>
                        <thead>
                            <tr className='bg-light'>
                                <th>
                                    API_KEY
                                </th>
                                <th>
                                    Block Status
                                </th>
                                <th>
                                    Created On
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <tr><td colSpan={5} className='text-center'> <Spinner animation="border" variant="primary" size="sm" /> Loading...</td></tr> :
                                apiKeys?.map((apiKey: any) =>
                                    <tr>
                                        <td>{apiKey.apiKey}</td>
                                        <td className='text-center'>
                                            <FormCheck
                                                checked={apiKey.blocked}
                                                onChange={() => toggleApiKey(apiKey._id)}
                                            />
                                        </td>
                                        <td>{moment(apiKey.createdOn).format("DD/MM/YYYY hh:mm a")}</td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                </Card>
            </div>
        </>
    )
}

export default ApiKeyIndex