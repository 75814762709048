import React, { useState } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PublicService from '../../services/public.service';


export default function Login() {

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [captchaToken, setCaptchaToken] = useState<any>();
    const [isActive, setIsActive] = useState<boolean>(false)


    const navigate = useNavigate();

    async function handleCaptchaChange(e: any) {
        setCaptchaToken(e);
        setIsActive(true)
    }


    const handleUserLogin = async () => {
        if (!username || !password) {
            toast.error("All Fields are required")
            return
        }
        await PublicService.userLogin(username, password, captchaToken).then(res => {
            if (res.status === 200) {
                sessionStorage.setItem("bnkauthkey", res.data.token)
                navigate("/dash")
            }
        }).catch(e => {
            console.error(e)
            toast.error(e.response.data);
        })
    }


    return (
        <div className="login-parent d-flex justify-content-center align-items-center">
            <Container>
                <Row>
                    <Col md={3}>

                    </Col>
                    <Col md={6}>
                        <h4 className="text-primary h2 text-center pb-2">
                            NIXBNK
                        </h4>
                        <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY || ""}
                                onChange={handleCaptchaChange}
                            />
                        </div>
                        <Card className="reg-card" style={{ width: "30rem;" }}>
                            <Card.Body>
                                <div>
                                    <Form.Group>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="User Name"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="User Name" onChange={(e) => setUsername(e.target.value)} />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group>
                                        <FloatingLabel controlId="floatingPassword" label="Password">
                                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group className="mt-2">
                                        <Button variant="primary" className="btn w-100 btn-lg" onClick={handleUserLogin} disabled={!isActive}>
                                            Login
                                        </Button>
                                    </Form.Group>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}