import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

interface ITextEditor {
    text: string
    setText: (value: string) => void
}
export default function TextEditor({ text, setText }: ITextEditor) {

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            // [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['image'],
            ['formula'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'script',
        'formula',
        'image'
    ];

    return (
        <>
            <ReactQuill
                value={text}
                onChange={setText}
                modules={modules}
                formats={formats}
                theme="snow"
                style={{ fontSize: "18px" }}
            />
        </>
    )
}
