import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Auth from './auth/Auth';
import PrivateRoutes from './Routes/Private.route';
import { IRouter, routes } from './Routes/routes';
import Login from './views/Login/Login';
import TranslatorById from './views/Translator/TranlatorById';


function PrivateRouter({ children }: any) {
    const auth = Auth.checkAuth();
    return auth ? <PrivateRoutes /> : <Navigate to="/login" />;
}


export default function MainRouter() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {/*path is dashboard the render privateRounter */}
                    <Route path="/dash" element={<PrivateRouter />}>
                        {routes.map((data: IRouter) => {
                            // data will follow poperty of IRouter
                            return (
                                <Route
                                    path={data.route + "/*"}
                                    element={<data.element />}
                                />
                            )
                        })}
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}