import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

export default function QALoader() {
    return (
        <>
            <Row>
                <Col md={8}>
                    <Skeleton count={5} height={250} />
                </Col>
                <Col md={4}>
                    <Skeleton height={500} count={2} />
                </Col>
            </Row>

        </>
    )
}