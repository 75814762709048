import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Image, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import QAService from '../../services/qa.service'
import ViewService from '../../services/view.service';
import QuestionsData from '../QA/QuestionsData';
import { BsArrowLeft } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import Select from "react-select"
import ViewComprehension from '../../components/Comprehension/ViewComprehension';
import ViewComprehensions from '../../components/Comprehension/Custom.Comprehension';

const UserViewNotVerifiedQuestion = () => {

    const params: any = useParams();
    const navigate = useNavigate();
    const tab = localStorage.getItem("tab")


    const [questionData, setQuestionData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [languages, setLaguages] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>()


    const getLanguageViewData = async () => {
        setLoading(true);
        await ViewService.getLanguageViewData(params.questionId, tab).then((res: any) => {
            if (res.status === 200) {
                const languageOptions = res.data.questionData.map((data: any) => ({
                    label: data.language,
                    value: data._id
                }));

                setLaguages(languageOptions);

                const initialLanguage = languageOptions.find((lang: any) => lang.value === params.languageId);
                setSelectedLanguage(initialLanguage ? initialLanguage.value : languageOptions[0].value);
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(false)
    }

    const handleNextQuestion = async () => {
        setLoading(true);
        await ViewService.getNextAndPrevQuestionByQuestionId(params.questionId, "next", tab).then((res: any) => {
            if (res.status === 200) {
                navigate(`/dash/view/question/${res.data.data.nextQuestionId}/${selectedLanguage}`);
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(false)
    }

    const handlePrevQuestion = async () => {
        setLoading(true);
        await ViewService.getNextAndPrevQuestionByQuestionId(params.questionId, "previous", tab).then((res: any) => {
            if (res.status === 200) {
                navigate(`/dash/view/question/${res.data.data.previousQuestionId}/${selectedLanguage}`);
            }
        }).catch(err => {
            console.log(err)
        })
        setLoading(false)
    }

    const getQuestionByQuestionId = async () => {
        setLoading(true);
        await ViewService.getQuestionByQuestionId(selectedLanguage).then((res: any) => {
            setQuestionData(res.data);
        })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (selectedLanguage) {
            getQuestionByQuestionId();
            navigate(`/dash/view/question/${params.questionId}/${selectedLanguage}`);
        }
    }, [selectedLanguage])

    useEffect(() => {
        if (params.questionId) {
            getLanguageViewData()
        }
    }, [params.questionId])

    if (loading) {
        return (
            <>
                <Row>
                    <Col md={8}>
                        <Skeleton height={100} />
                        <Skeleton height={215} count={4} />
                    </Col>
                    <Col md={4}>
                        <Skeleton height={500} count={2} />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <span
                        className='d-flex align-items-center xcn-text-15  mb-3'
                        onClick={() => navigate(`/dash/view?tab=${localStorage.getItem("tab")}`)}
                        style={{ cursor: 'pointer' }}
                    >
                        <BsArrowLeft /> {" "}
                        <span className='fw-bold xcn-text-15 text-muted'>Back</span>
                    </span>
                </div>
                <div className='d-flex gap-2'>
                    <Select
                        options={languages}
                        value={languages?.find((data: any) => data.value === selectedLanguage)}
                        onChange={(e: any) => setSelectedLanguage(e.value)}
                    />
                    <Button onClick={handlePrevQuestion}>{"<<"}Prev</Button>
                    <Button onClick={handleNextQuestion}>Next{">>"}</Button>
                </div>
            </div>

            <Row>
                <Col md={8}>
                    <Card className="reg-card box-shadow mt-3 mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Difficulty: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.difficulty ? questionData.difficulty : "0"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Frequency Used: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.frequencyUsed ? questionData.frequencyUsed : "0"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Subject: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.subjectId?.name ? questionData.subjectId.name : "--"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Language: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.language ? questionData.language : "--"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Option Type: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.optionType ? questionData.optionType : "--"}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bold xcn-text-15 text-muted'>Question Type: </span>
                                        <span className='fw-bold xcn-text-15'>{questionData && questionData.questionType ? questionData.questionType : "--"}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {questionData?.questionType === "comprehension" ? <ViewComprehensions data={questionData} /> :
                        <QuestionsData questionData={questionData} />}
                </Col>
            </Row>
        </>
    )
}

export default UserViewNotVerifiedQuestion
