import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Alert, Image, Card } from 'react-bootstrap';
import QAService from '../../services/qa.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'react-h5-audio-player';
import { IMedia, IQuestion } from '../helpher/interfaces/Comprehension.interface';
import { MediaRenderer } from '../helpher/MediaRender.helper';
import { Question } from './Question';
import { QaTheory } from './QaTheory';
import ExplanationComponent from '../Explanation/ExplanationCompoent';



interface IViewComprehensionProps {
    data: {
        theory: IMedia;
        instructions: string;
        questions: IQuestion[];
        explanation: any
    };
}


const ViewComprehensions: React.FC<IViewComprehensionProps> = ({ data }) => {
    const { theory, instructions, questions } = data;
    const [questionMedia, setQuestionMedia] = useState<any[]>([]);


    const getEncQuestionMedia = async (type: 'image' | 'video' | 'audio' | 'doc', url: string | undefined, questionIndex: number) => {
        if (url) {
            try {
                const res = await QAService.getEncMultimedia(url);
                if (res.status === 200) {
                    setQuestionMedia(prev => {
                        const newMedia = [...prev];
                        newMedia[questionIndex] = newMedia[questionIndex] || {};
                        newMedia[questionIndex][type] = type === 'image' ? res.data : res.data.toString();
                        return newMedia;
                    });
                }
            } catch (error) {
                console.error(`Failed to fetch question ${type}:`, error);
            }
        }
    };

    const getEncOptionMedia = async (type: 'image' | 'video' | 'audio' | 'doc', url: string | undefined, questionIndex: number, optionIndex: number) => {
        if (url) {
            try {
                const res = await QAService.getEncMultimedia(url);
                if (res.status === 200) {
                    setQuestionMedia((prev: any) => {
                        const newMedia = [...prev];
                        if (!newMedia[questionIndex]) {
                            newMedia[questionIndex] = { options: [] };
                        }
                        if (!newMedia[questionIndex].options) {
                            newMedia[questionIndex].options = [];
                        }
                        if (!newMedia[questionIndex].options[optionIndex]) {
                            newMedia[questionIndex].options[optionIndex] = {};
                        }
                        newMedia[questionIndex].options[optionIndex][type] = type === 'image' ? res.data : res.data.toString();
                        return newMedia;
                    });
                }
            } catch (error) {
                console.error(`Failed to fetch option ${type}:`, error);
            }
        }
    };

    return (
        <div>
            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                <Card.Body>
                    <h5 className="mt-3 mb-2">1. Instructions</h5>
                    <Form.Group>
                        <Form.Control as="textarea" readOnly rows={3} defaultValue={instructions} />
                    </Form.Group>
                </Card.Body>
            </Card>
            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                <Card.Body>
                    <QaTheory theory={theory} />
                </Card.Body>
            </Card>
            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                <Card.Body>
                    {questions.map((question: any, index: number) => {
                        return (
                            <Question
                                key={index}
                                question={question}
                                questionIndex={index}
                                questionMedia={questionMedia}
                                getEncQuestionMedia={getEncQuestionMedia}
                                getEncOptionMedia={getEncOptionMedia}
                            />
                        )
                    })}
                </Card.Body>
            </Card>


            <div>
                {data && data.explanation && <ExplanationComponent questionData={data} />}
            </div>
        </div>
    );
};

export default ViewComprehensions;
