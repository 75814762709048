import React from 'react'
import UserViewNotVerifiedQuestion from './UserViewNotVerifiedQuestion'


const View = () => {
    return (
        <div>
            <div className="mt-4">
                <div>
                    <UserViewNotVerifiedQuestion/>
                </div>
            </div>
        </div>
    )
}

export default View