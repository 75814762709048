import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap'

const PreviewModal = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {props.type === "image" &&
                    <Image src={props.image && props.image.split(";base64,")[1] ? props.image : "data:image/jpeg;base64," + props.image} 
                    // alt={"data:image/jpeg;base64," + props.image} 
                    className="w-100" />
                }

                {props.type === "video" &&
                    <video width="320" height="240" controls>
                        <source type="video/mp4" src={props.video} />
                        <source type="video/ogg" src={props.video} />
                    </video>
                }

                {props.type === "audio" &&
                    <audio controls>
                        <source type="audio/ogg" src={props.audio} />
                        <source type="audio/mpeg" src={props.audio} />
                    </audio>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PreviewModal