import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import RequestService from '../../services/request.service';
import exportFromJSON from 'export-from-json'

export default function RequestsIndex() {

    const downloadCSV = () => {
        const data = logs;
        const fileName = `logs-${new Date().toISOString()}`;
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName, exportType })
    }
    const [logs, setLogs] = useState<any>([]);

    const getLogs = async () => {
        await RequestService.getLogs().then(res => {
            if (res.status === 200) {
                console.log(res.data)
                setLogs(res.data)
            }
        })
    }
    useEffect(() => {
        getLogs()
    }, []);


    return (
        <>
            <h5 className="mt-2">
                All Requests
            </h5>
            <div className="mt-2">
                <Card className="reg-card bg-white p-3">
                    <div>
                    <Button 
                        onClick={downloadCSV}
                        className='btn-primary'
                    >DOWNLOAD CSV</Button>
                    </div>
                    
                </Card>
            </div>
        </>
    )
}