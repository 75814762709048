import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import DownloadQpService from '../../services/downloadQp.service';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const download = require("downloadjs");

export default function QPDownloadIndex() {

    const [questionPaper, setQuestionPaper] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getAllCreatedQuestionPapers = async () => {
        setLoading(true);
        await DownloadQpService.getAllQPForUser().then(res => {
            if (res.status === 200) {
                setQuestionPaper(res.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
            setLoading(false);
        })
    }
    
    const downloadQuestionPaperZip = async (qp_id:any) => {
        await DownloadQpService.getQuestionPaperZip(qp_id).then(res => {
            if(res.status === 200) {
                download(res.data, "qp.zip", "application/zip");
            }
        })
    }

    useEffect(() => {
        getAllCreatedQuestionPapers()
    }, [])

    if(loading) {
        return (
            <Skeleton  height={600} />
        )
    }

    return (
        <>
            <div className="mt-2">
                <Card className="reg-card bg-white">
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th>
                                    S.No.
                                </th>
                                <th>
                                    ExamId
                                </th>
                                <th>
                                    ShiftId
                                </th>
                                <th>
                                    Question Paper Id
                                </th>
                                <th>
                                    CreatedAt
                                </th>
                                {/* <th>
                                    Actions
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <tr><td colSpan={5} className="text-center">Loading... <Spinner animation="border" variant="primary" /> </td></tr> :
                            questionPaper && questionPaper.length > 0 && questionPaper.map((data: any, index: number) => {
                                return (
                                    <tr>
                                        <td className='fw-bold '>
                                            {index + 1}
                                        </td>
                                        <td>
                                           {data.examId}
                                        </td>
                                        <td>
                                            {data.shiftId}
                                        </td>
                                        <td>
                                            {data._id}
                                        </td>
                                        <td>
                                            {moment(data.createdAt).format("DD/MM/YYYY hh:mm a")}
                                        </td>
                                        {/* <td>
                                            <FontAwesomeIcon icon={faDownload} className="text-warning" size="sm" onClick={()=>downloadQuestionPaperZip(data._id)} />
                                        </td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>
            </div>
        </>
    )
}