import { useEffect, useState } from "react"
import { TranslatorService } from "../../services/translator.service";
import { Button, Card } from "react-bootstrap";
import TranslatorQuestion from "../../components/Table/TranslatorQuestion.table";
import Skeleton from "react-loading-skeleton";
import TablePagination from "../../components/Pagination/Table.paginaition";
import toast from "react-hot-toast";
import { FaFileAlt } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import Select from "react-select"
import UserProfileService from "../../services/userprofile.service";
import PublicService from "../../services/public.service";

export default function Translator() {

    const [questionData, setQuestionData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);


    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(20);


    const [topicsData, setTopicsData] = useState<any>();
    const [subjects, setSubjects] = useState<any[]>();
    const [languages, setLanguages] = useState<any>();
    const [selectedQuestionSubject, setSelectedQuestionSubject] = useState<any>();
    const [selectTopicId, setSelectTopicId] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>()

    const dummy_data = [
        {
            label: "Data",
            value: "data"
        }
    ]

    const getAllSubjects = async () => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getTopicsAndSubTopics = async (selectedSubjectId?: any) => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const selectedSubject = res.data.find((data: any) => data._id === selectedSubjectId);

                if (selectedSubject) {
                    const topics = selectedSubject.topics.map((topic: any) => ({
                        label: topic.name,
                        value: topic._id,
                    }));
                    setTopicsData(topics);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllLanguage = async () => {
        await PublicService.getLanguage().then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                setLanguages(Object.values(res.data).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                }))
            }
        })
    }

    const getAllQuestion = async () => {
        setLoading(true);
        await TranslatorService.getAllQuestion(currentPage, perPage, selectedQuestionSubject?.value, selectTopicId?.value, selectedLanguage?.value).then((res) => {
            if (res.status === 200) {
                setQuestionData(res.data)
                setTotalCount(res.data.totalCount)
            }
        })
            .catch(err => {
                console.error(err);
                toast.error(err.response.data)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getAllQuestion();
    }, [currentPage, perPage, selectedQuestionSubject, selectTopicId, selectedLanguage])

    useEffect(() => {
        getAllSubjects();
        getAllLanguage();
    }, [])

    useEffect(() => {
        if (selectedQuestionSubject?.value) {
            getTopicsAndSubTopics(selectedQuestionSubject?.value);
        }

    }, [selectedQuestionSubject, selectTopicId])




    return (
        <>

            <div>
                <Card className="shadow-sm p-3">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>Questions</h4>
                            {/* <Button variant="outline-secondary">
                                <IoFilterSharp className="fs-5" />
                            </Button> */}
                        </div>
                        <div className="d-flex align-items-center gap-3 mb-3">
                            <Select
                                placeholder="Select Subject..."
                                options={subjects} className="w-25"
                                onChange={e => setSelectedQuestionSubject(e)}
                                isClearable
                                isSearchable
                            />
                            <Select
                                placeholder="Select Topic..."
                                options={topicsData} className="w-25"
                                onChange={e => setSelectTopicId(e)}
                                isClearable
                                isSearchable
                            />
                            <Select
                                placeholder="Select Language..."
                                options={languages} className="w-25"
                                onChange={e => setSelectedLanguage(e)}
                                isClearable
                                isSearchable
                            />
                        </div>
                        {
                            loading ?
                                <Skeleton count={5} height={100} />
                                :
                                <div>
                                    <TranslatorQuestion tableData={questionData} />
                                </div>
                        }
                    </Card.Body>
                </Card>
                <div className="text-center my-3">
                    <TablePagination
                        total={totalCount}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={(e: number) => {
                            setCurrentPage(e);
                        }}
                        setPerPage={(e: number) => { setPerPage(e); }}
                    />
                </div>
            </div >
        </>
    )
}