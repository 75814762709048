import { useEffect } from "react";
import { Row, Col, Form, Alert, Card } from "react-bootstrap"
import { MediaRenderer } from "../helpher/MediaRender.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from 'react-h5-audio-player';
import { IQuestion } from "../helpher/interfaces/Comprehension.interface";
import ExplanationComponent from "../Explanation/ExplanationCompoent";



export const Question = ({ question, questionIndex, questionMedia, getEncQuestionMedia, getEncOptionMedia }: { question: IQuestion, questionIndex: number, questionMedia: any[], getEncQuestionMedia: any, getEncOptionMedia: any }) => {

    useEffect(() => {
        getEncQuestionMedia('image', question.image, questionIndex);
        getEncQuestionMedia('audio', question.audio, questionIndex);
        getEncQuestionMedia('video', question.video, questionIndex);
        getEncQuestionMedia('doc', question.doc, questionIndex);
        question?.options?.forEach((option: any, optionIndex: number) => {
            getEncOptionMedia('image', option.image, questionIndex, optionIndex);
            getEncOptionMedia('audio', option.audio, questionIndex, optionIndex);
            getEncOptionMedia('video', option.video, questionIndex, optionIndex);
            getEncOptionMedia('doc', option.doc, questionIndex, optionIndex);
        });
    }, [question, questionIndex]);

    return (
        <div className="mb-4">
            <h6>Question {questionIndex + 1}</h6>
            <Row className="mb-2">
                <Col>
                    <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Text</Form.Label>
                                <Form.Control as="textarea" readOnly rows={5} defaultValue={question.text} />
                            </Form.Group>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            {question.image && questionMedia[questionIndex] && questionMedia[questionIndex].image &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-3">
                            <Form.Label>Question Image</Form.Label>
                            <MediaRenderer type='image' data={questionMedia[questionIndex].image} />
                        </div>
                    </Card.Body>
                </Card>
            }
            {question.audio && questionMedia[questionIndex] && questionMedia[questionIndex].audio &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mb-3">
                            <Form.Label>Question Audio</Form.Label>
                            <AudioPlayer
                                autoPlay
                                src={questionMedia[questionIndex].audio}
                                onPlay={() => console.log("onPlay")}
                                muted
                            />
                        </div>
                    </Card.Body>
                </Card>

            }
            {question.video && questionMedia[questionIndex] && questionMedia[questionIndex].video &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Form.Label>Question Video</Form.Label>
                        <div>
                            <video width="320" height="240" controls>
                                <source type="video/mp4" src={questionMedia[questionIndex].video} />
                                <source type="video/ogg" src={questionMedia[questionIndex].video} />
                            </video>
                        </div>
                    </Card.Body>
                </Card>
            }

            {question.doc && questionMedia[questionIndex] && questionMedia[questionIndex].doc &&

                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Form.Label>Question Video</Form.Label>
                        <div className="mb-3">
                            <Form.Label>Question PDF</Form.Label>
                            <embed src={questionMedia[questionIndex].doc} type="application/pdf" width="100%" height="400px" />
                        </div>
                    </Card.Body>
                </Card>
            }

            {question && question?.options?.map((option: any, optionIndex: number) => {
                return (

                    <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD80", borderRadius: 8, outline: "none", border: "none" }}>
                        <Card.Body>
                            <div key={optionIndex} className="mb-3">
                                <Form.Group>
                                    <Form.Label>Option {optionIndex + 1}</Form.Label>
                                    <Form.Control type="text" readOnly defaultValue={option.text} />
                                </Form.Group>
                            </div>
                        </Card.Body>
                    </Card>
                )
            })}
        </div>
    );
};