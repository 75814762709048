import { Button, Image, Modal } from "react-bootstrap";


interface IPreviewImageModal {
    show: any,
    handleClose: any
}

export default function PreviewImageModal(props: IPreviewImageModal) {


    return (
        <Modal
            show={props.show ? true : false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <Image src={props.show}
                    className="w-100" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}