import { faCheckCircle, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Form, Col, Row, Button, Card } from "react-bootstrap"
import toast from 'react-hot-toast';
import { FaPlus, FaTrash } from 'react-icons/fa';
import UploadService from '../../../services/upload.service';


interface IEditComprehensionProps {
    questionData: any,
    setQuestionData: any
    compCorrectAnswer: any,
    setCompCorrectAnswer: any
}

export default function EditComprehension(props: IEditComprehensionProps) {


    const [questionCount, setQuestionCount] = useState<number>(1);
    const [optionCount, setOptionCount] = useState<number>(1);



    const handleAddQuestions = () => {
        props.setQuestionData((prevState: any) => ({
            ...prevState,
            questions: [...prevState.questions, { text: '', image: '', audio: '', video: '', doc: '', answers: '', options: [] }]
        }));
    };

    const handleRemoveQuestions = (index: number) => {
        props.setQuestionData((prevState: any) => ({
            ...prevState,
            questions: prevState.questions.filter((_: any, i: number) => i !== index)
        }));
    };

    const handleAddOption = (questionIndex: number) => {
        props.setQuestionData((prevState: any) => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions[questionIndex].options.push({ text: '', image: '', audio: '', video: '', doc: '' });
            return {
                ...prevState,
                questions: updatedQuestions
            };
        });
    };

    const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
        props.setQuestionData((prevState: any) => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions[questionIndex].options = updatedQuestions[questionIndex].options.filter((_: any, i: number) => i !== optionIndex);
            return {
                ...prevState,
                questions: updatedQuestions
            };
        });
    };

    const handleInstructionData = (e: any) => {
        props.setQuestionData({ ...props.questionData, ["instructions"]: e.target.value })
    }

    const handleTheoryTextData = (e: any) => {
        const theory_data = { ...props.questionData?.theory, ["text"]: e.target.value }
        props.setQuestionData({ ...props.questionData, theory: theory_data })
    }

    const handleTheoryData = async (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            toast.promise(
                UploadService.uploadMultimedia(formData)
                    .then((res) => {
                        if (res.status === 200) {
                            props.setQuestionData({ ...props.questionData, theory: { ...props.questionData.theory, [name]: res.data } });
                        }
                    }),
                {
                    loading: 'Uploading',
                    success: 'Uploaded',
                    error: 'Something went wrong when uploading Multimedia',
                }
            );
        }
    };


    const handleAddQuestionData = async (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        i: number
    ) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            toast.promise(
                UploadService.uploadMultimedia(formData).then((res) => {
                    if (res.status === 200) {
                        const currentQuestions = [...props.questionData.questions];

                        if (!currentQuestions[i]) {
                            currentQuestions[i] = {};
                        }
                        currentQuestions[i][name] = res.data;

                        props.setQuestionData({ ...props.questionData, questions: currentQuestions });
                    }
                }),
                {
                    loading: 'Uploading',
                    success: 'Uploaded',
                    error: 'Something went wrong when uploading Multimedia',
                }
            );
        }
    };

    const handleOptionData = async (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        optionIndex: number,
        questionIndex: number
    ) => {
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            try {
                let formData = new FormData();
                formData.append('file', file);

                const res = await UploadService.uploadMultimedia(formData);

                if (res.status === 200) {
                    const updatedData = [...props.questionData.questions];

                    if (!updatedData[questionIndex]) {
                        updatedData[questionIndex] = { options: [] };
                    }
                    if (!updatedData[questionIndex].options[optionIndex]) {
                        updatedData[questionIndex].options[optionIndex] = {};
                    }
                    updatedData[questionIndex].options[optionIndex][name] = res.data;
                    props.setQuestionData({ ...props.questionData, questions: updatedData });

                    toast.success('File uploaded successfully');
                } else {
                    toast.error(`Upload failed with status: ${res.status}`);
                }
            } catch (error) {
                console.error(error);
                toast.error('Something went wrong when uploading multimedia');
            }
        } else {
            toast.error('No file selected');
        }
    };


    const handleQuestionText = (e: any, i: number) => {
        const currentQuestions = [...props.questionData.questions];

        if (!currentQuestions[i]) {
            currentQuestions[i] = {};
        }
        currentQuestions[i]["text"] = e.target.value;

        props.setQuestionData({ ...props.questionData, questions: currentQuestions });
    };

    const handleOptionTextChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        optionIndex: number,
        questionIndex: number
    ) => {
        const text = e.target.value;

        const updatedData = [...props.questionData.questions];

        if (!updatedData[questionIndex]) {
            updatedData[questionIndex] = { options: [] };
        }

        if (!updatedData[questionIndex].options) {
            updatedData[questionIndex].options = [];
        }

        if (!updatedData[questionIndex].options[optionIndex]) {
            updatedData[questionIndex].options[optionIndex] = {};
        }
        updatedData[questionIndex].options[optionIndex][name] = text;
        props.setQuestionData({ ...props.questionData, questions: updatedData });
    };

    // const handleComprehensionCorrectAnswer = (e: any, questionIndex: any, optionId: any) => {
    //     const currentComprehensionQuestions = [...props.questionData.questions];

    //     const answers = currentComprehensionQuestions[questionIndex].answers || [];

    //     if (e) {
    //         if (!answers.includes(optionId)) {
    //             answers.push(optionId);
    //         }
    //     } else {
    //         currentComprehensionQuestions[questionIndex].answers = answers.filter(
    //             (id: string) => id !== optionId
    //         );
    //     }

    //     props.setQuestionData({ ...props.questionData, questions: currentComprehensionQuestions });
    // };

    const handleCorrectAnswer = (e: any, index: number) => {
        if (e) {
            props.setCompCorrectAnswer((arr: any) => [...arr, index]);
        } else {
            const isIndexAvailable = props.compCorrectAnswer?.includes(index);

            if (isIndexAvailable) {
                const updatedArray = props.compCorrectAnswer?.filter((item: number) => item !== index);
                props.setCompCorrectAnswer(updatedArray);
            }
        }
    };


    console.log(props.questionData, "answer")


    const handleExplanationData = async (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const files = e.target.files;

        if (files) {
            let updatedExplanations = props.questionData.explanation ? { ...props.questionData.explanation } : {};

            if (!updatedExplanations[name]) {
                updatedExplanations[name] = [];
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                let formData = new FormData();
                formData.append('file', file);

                await toast.promise(
                    UploadService.uploadMultimedia(formData).then((res) => {
                        if (res.status === 200) {
                            updatedExplanations[name].push(res.data);
                        }
                    }),
                    {
                        loading: 'Uploading',
                        success: 'Uploaded',
                        error: 'Something went wrong when uploading Multimedia',
                    }
                );
            }

            props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
        }
    };

    const handleExplanationDelete = (mediaType: string, index: number) => {
        const updatedExplanations = { ...props.questionData.explanation };

        updatedExplanations[mediaType].splice(index, 1);

        if (updatedExplanations[mediaType].length === 0) {
            delete updatedExplanations[mediaType];
        }

        props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
    };

    const handleTextExplanationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedExplanations = { ...props.questionData.explanation };

        updatedExplanations.text[0] = e.target.value;

        props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
    };


    console.log(props.compCorrectAnswer, "comp correct answe.....")



    return (
        <>
            <div>
                <h5 className="mt-3 mb-2">1. Instruction</h5>
                <div>
                    <Form.Group>
                        <Form.Control as="textarea" rows={5} cols={5} defaultValue={props.questionData?.instructions} onChange={(handleInstructionData)} />
                    </Form.Group>
                </div>
            </div>
            <hr />
            <div className="mt-3">
                <h5 className="mt-3 mb-2">2. Theory</h5>
                <div>
                    <Row>
                        <Col md={8}>
                            <Row className="mb-2">
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Text</Form.Label>
                                        <Form.Control as="textarea" rows={11} cols={5} defaultValue={props.questionData?.theory?.text} onChange={handleTheoryTextData} />
                                    </Form.Group>
                                </Col>
                            </Row></Col>
                        <Col>
                            <div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    {props.questionData?.theory?.image && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                    </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                            <FontAwesomeIcon icon={faEye} /> Preview
                                        </span></>}
                                    <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: any) => handleTheoryData(e, "image")} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    {props.questionData?.theory?.audio && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                    </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                            <FontAwesomeIcon icon={faEye} /> Preview
                                        </span></>}
                                    <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: any) => handleTheoryData(e, "audio")} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    {props.questionData?.theory?.video && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                    </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                            <FontAwesomeIcon icon={faEye} /> Preview
                                        </span></>}
                                    <Form.Control type="file" name="video" accept="video/*" onChange={(e: any) => handleTheoryData(e, "video")} />
                                </div>
                                <div className="mb-1">
                                    <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                    {props.questionData?.theory?.doc && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                    </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                            <FontAwesomeIcon icon={faEye} /> Preview
                                        </span></>}
                                    <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: any) => handleTheoryData(e, "doc")} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <hr />
            <div className="mt-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h5 className="mt-3">3. Add Questions</h5>
                    <Button size="sm" className="rounded-0 text-white" onClick={handleAddQuestions}>Add</Button>
                </div>

                {props.questionData?.questions?.map((data: any, questionIndex: number) => (
                    <Card key={questionIndex} className="px-2 mb-2" style={{ backgroundColor: "#D0D5DD40", borderRadius: 8, outline: "none", border: "none" }}>
                        <Card.Body>
                            <p className="fw-bold text-muted">Question {questionIndex + 1}</p>
                            <Row className="mb-2">
                                <Col md={8}>
                                    <Form.Group>
                                        <Form.Label>Text</Form.Label>
                                        <Form.Control as="textarea" rows={11} cols={5} defaultValue={data?.text} onChange={(e: any) => handleQuestionText(e, questionIndex)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            {data?.image && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                            </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                    <FontAwesomeIcon icon={faEye} /> Preview
                                                </span></>}
                                            <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: any) => handleAddQuestionData(e, "image", questionIndex)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            {data?.audio && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                            </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                    <FontAwesomeIcon icon={faEye} /> Preview
                                                </span></>}
                                            <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: any) => handleAddQuestionData(e, "audio", questionIndex)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            {data?.video && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                            </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                    <FontAwesomeIcon icon={faEye} /> Preview
                                                </span></>}
                                            <Form.Control type="file" name="video" accept="video/*" onChange={(e: any) => handleAddQuestionData(e, "video", questionIndex)} />
                                        </div>
                                        <div className="mb-1">
                                            <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            {data?.doc && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                            </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                    <FontAwesomeIcon icon={faEye} /> Preview
                                                </span></>}
                                            <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: any) => handleAddQuestionData(e, "doc", questionIndex)} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <h5>Options Data</h5>
                                    <Button className="d-flex align-items-center gap-2 mb-2" onClick={() => handleAddOption(questionIndex)}>
                                        <FaPlus />
                                        Add Options
                                    </Button>
                                </div>
                                <Col>
                                    {data?.options?.map((option: any, index: number) => {
                                        const correct_answer = data?.answers?.includes(option._id);
                                        return (
                                            <Card className="mt-2" style={{ borderRadius: 8, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <p className="fw-bold text-muted">Option {index + 1}</p>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Form.Group>
                                                                <Form.Label>Text</Form.Label>
                                                                <Form.Control as="textarea"
                                                                    rows={11}
                                                                    cols={5}
                                                                    defaultValue={option?.text}
                                                                    onChange={(e: any) => handleOptionTextChange(e, "text", index, questionIndex)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Image</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                {option?.image && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                                    </span></>}
                                                                <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={(e: any) => handleOptionData(e, "image", index, questionIndex)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Audio</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                {option?.audio && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                                    </span></>}
                                                                <Form.Control type="file" name="audio" accept="audio/*" onChange={(e: any) => handleOptionData(e, "audio", index, questionIndex)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Video</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                {option?.video && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                                    </span></>}
                                                                <Form.Control type="file" name="video" accept="video/*" onChange={(e: any) => handleOptionData(e, "video", index, questionIndex)} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Form.Label className="xcn-text-12 text-secondary">Upload Pdf</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                                                {option?.doc && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                                    </span></>}
                                                                <Form.Control type="file" name="pdf" accept=".pdf" onChange={(e: any) => handleOptionData(e, "doc", index, questionIndex)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <div className="d-flex align-items-center gap-4">
                                                            <div className="fs-5">
                                                                <Form.Check
                                                                    className="text-success"
                                                                    checked={props.compCorrectAnswer.includes(index)}
                                                                    onChange={(e: any) => handleCorrectAnswer(e.target.checked, index)}
                                                                />
                                                            </div>
                                                            <FaTrash className="text-danger" onClick={() => handleRemoveOption(questionIndex, index)} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })}
                                </Col>
                            </Row>
                            <div className="mt-3 d-flex justify-content-end">
                                <Button className="d-flex align-items-center gap-2" variant="danger" onClick={() => handleRemoveQuestions(questionIndex)} style={{ opacity: .9 }}>
                                    <FaTrash />
                                    Delete Question
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
            <hr />
            <div className="mt-3">
                <h5 className="mt-3 mb-2">Explanation</h5>
                <Row>
                    <Col>
                        <Card className="px-2 py-1 mb-2 me-4" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Explanation Text</Form.Label>
                                    <Form.Control
                                        className="w-50"
                                        as="textarea"
                                        rows={10}
                                        cols={10}
                                        defaultValue={props.questionData?.explanation?.text[0]}
                                        onChange={(e: any) => handleTextExplanationChange(e)}
                                    />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <div>
                    <Row>
                        <Row>
                            <Col>
                                <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                    {props.questionData?.explanation?.audio?.length > 0 ? props.questionData?.explanation?.audio?.map((data: any, index: number) => {
                                        return (
                                            <div>
                                                <Row>
                                                    <Col key={index}>
                                                        <div key={index} className="d-flex justify-content-between align-items-center">
                                                            <Form.Label className="xcn-text-12 text-secondary">
                                                                Audio {index + 1}
                                                            </Form.Label>
                                                            <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                <FontAwesomeIcon icon={faEye} />
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    className="ms-2 text-danger"
                                                                    onClick={() => handleExplanationDelete("audio", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        )
                                    }) : "No data found!!!"}
                                </Card>
                                <Col>
                                    <Form.Label>Upload Audio</Form.Label>
                                    <Form.Control type="file" name="audio" accept="audio/*" multiple onChange={(e: any) => handleExplanationData(e, "audio")} />
                                </Col>
                            </Col>
                            <Col>
                                <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                    {props.questionData?.explanation?.image?.length > 0 ? props.questionData?.explanation?.image?.map((data: any, index: number) => {
                                        return (
                                            <div>
                                                <Row>
                                                    <Col key={index}>
                                                        <div key={index} className="d-flex justify-content-between align-items-center">
                                                            <Form.Label className="xcn-text-12 text-secondary">
                                                                Image {index + 1}
                                                            </Form.Label>
                                                            <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                <FontAwesomeIcon icon={faEye} />
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    className="ms-2 text-danger"
                                                                    onClick={() => handleExplanationDelete("image", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }) : "No data found!!!"}
                                </Card>
                                <Col>
                                    <Form.Label>Upload Image</Form.Label>
                                    <Form.Control type="file" name="image" accept="image/png, image/jpeg" multiple onChange={(e: any) => handleExplanationData(e, "image")} />
                                </Col>
                            </Col>
                            <Col>
                                <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                    {props.questionData?.explanation?.video?.length > 0 ? props.questionData?.explanation?.video?.map((data: any, index: number) => {
                                        return (
                                            <div>
                                                <Row>
                                                    <Col key={index}>
                                                        <div key={index} className="d-flex justify-content-between align-items-center">
                                                            <Form.Label className="xcn-text-12 text-secondary">
                                                                Video {index + 1}
                                                            </Form.Label>
                                                            <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                <FontAwesomeIcon icon={faEye} />
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    className="ms-2 text-danger"
                                                                    onClick={() => handleExplanationDelete("video", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }) : "No data found!!!"}
                                </Card>
                                <Col>
                                    <Form.Label>Upload Video</Form.Label>
                                    <Form.Control type="file" name="video" accept="video/*" multiple onChange={(e: any) => handleExplanationData(e, "video")} />
                                </Col>
                            </Col>
                            <Col>
                                <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                    {props.questionData?.explanation?.doc?.length > 0 ? props.questionData?.explanation?.doc?.map((data: any, index: number) => {
                                        return (
                                            <div>
                                                <Row>
                                                    <Col key={index}>
                                                        <div key={index} className="d-flex justify-content-between align-items-center">
                                                            <Form.Label className="xcn-text-12 text-secondary">
                                                                Doc {index + 1}
                                                            </Form.Label>
                                                            <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                <FontAwesomeIcon icon={faEye} />
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    className="ms-2 text-danger"
                                                                    onClick={() => handleExplanationDelete("doc", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }) : "No data found!!!"}
                                </Card>
                                <Col>
                                    <Form.Label>Upload PDF</Form.Label>
                                    <Form.Control type="file" name="doc" accept=".pdf" multiple onChange={(e: any) => handleExplanationData(e, "doc")} />
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </div>
            <hr />
        </>
    )
}
