import { faPlus, faMinus, faTimesCircle, faCheckCircle, faDownload, faFloppyDisk, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { OptionTypeEnum } from '../../../enums/question.enum';
import HandleChangeHelper from '../../../helper/handlerChange.helper';
import PreviewModal from '../../../Modal/preview.modal';
import PublicService from '../../../services/public.service';
import QAService from '../../../services/qa.service';
import UserProfileService from '../../../services/userprofile.service';
import ViewService from '../../../services/view.service';
import Explanation from '../../../components/CustomComponent/Explanation';
import UploadService from '../../../services/upload.service';
import EditComprehension from './EditComprehension';


interface IViewQuestion {
    questionData: any
    setQuestionData: any
    saveQuestion: any
    correctAnswer: any
    setCorrectAnswer: any
    setQuestionText: any
    compCorrectAnswer: any
    setCompCorrectAnswer: any
}

export default function ViewQuestion(props: IViewQuestion) {
    const { questionId } = useParams();



    const [questionTypes, setQuestionTypes] = useState<any[]>();
    const [subjects, setSubjects] = useState<any[]>([]);

    const [languages, setLanguages] = useState<any[]>();
    const [difficultyLevel, setDifficultyLevel] = useState<any[]>();
    const [examCategory, setExamCategory] = useState<any>();

    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    const [showAudioModal, setShowAudioModal] = useState<boolean>(false);
    const [questionData, setQuestionData] = useState<any>();

    const [encData, setEncData] = useState<any>()
    const [encVideo, setEncVideo] = useState<any>("");
    const [encAudio, setEncAudio] = useState<any>();

    const [toggleQuestion, setToggleQuestion] = useState<boolean>(false)




    const [subTopicData, setSubTopicData] = useState<any>();
    const [topicsData, setTopicsData] = useState<any>();
    const [currentTopic, setCurrentTopic] = useState<any>();
    const [currentSubTopic, setCurrentSubTopic] = useState<any>();




    const handleCorrectAnswer = (e: any, index: number) => {
        if (e) {
            props.setCorrectAnswer((arr: any) => [...arr, index]);
        } else {
            const isIndexAvailable = props.correctAnswer?.includes(index);

            if (isIndexAvailable) {
                const updatedArray = props.correctAnswer?.filter((item: number) => item !== index);
                props.setCorrectAnswer(updatedArray);
            }
        }
    };



    const getQuestionById = async () => {
        await ViewService.getQuestionByQuestionId(questionId).then((res) => {
            if (res.status === 200) {
                console.log(res.data, "data question");
                setQuestionData(res.data);
            }
        })
    }

    const getEncMediaImage = async () => {
        await QAService.getEncMultimedia(questionData?.image).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }

    const getEncMediaVideo = async () => {
        await QAService.getEncMultimedia(questionData && questionData.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res.data.toString());
            }
        }))
    }

    const getEncMediaAudio = async () => {
        await QAService.getEncMultimedia(questionData && questionData.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res.data.toString());
            }
        }))
    }

    const getAllQuestionTypes = async () => {
        await UserProfileService.getAllQuestionTypes().then(res => {
            if (res.status === 200) {
                const questiontypes = res.data.map((data: string) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setQuestionTypes(questiontypes)
            }
        })
    }

    const getExamCategory = async () => {
        await PublicService.getExamCategory().then(res => {
            if (res.status === 200) {
                const category = Object.values(res.data).map((data: any) => {
                    return {
                        label: data?.name,
                        value: data?._id
                    }
                })
                setExamCategory(category);
            }
        })
    }

    const handleQuestionDataChange = (e: any) => {
        props.setQuestionData({ ...props.questionData, [e.target.name]: e.target.value })
    }

    const getAllSubjects = async () => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const subjects = res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id,
                    };
                });
                setSubjects(subjects);
            }
        } catch (e) {
            console.error(e);
        }
    };


    const getTopicsAndSubTopics = async (selectedSubjectId?: any, selectedTopicId?: any) => {
        try {
            const res = await UserProfileService.getAllSubjects();
            if (res.status === 200) {
                const selectedSubject = res.data.find((data: any) => data._id === selectedSubjectId);

                if (selectedSubject) {
                    const topics = selectedSubject.topics.map((topic: any) => ({
                        label: topic.name,
                        value: topic._id,
                    }));

                    if (selectedTopicId) {
                        const selectedTopic = selectedSubject.topics.find((topic: any) => topic._id === selectedTopicId);
                        const subTopics = selectedTopic ? selectedTopic.sub_topics.map((subTopic: any) => ({
                            label: subTopic.name,
                            value: subTopic._id,
                        })) : [];
                        setSubTopicData(subTopics);
                    }

                    setTopicsData(topics);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };


    const getLanguage = async () => {
        await PublicService.getLanguage().then(res => {
            if (res.status === 200) {
                const language = Object.values(res.data).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setLanguages(language);
            }
        })
    }

    const getDifficultyLevel = () => {
        let data = []
        for (let i = 1; i <= 10; i++) {
            data.push({
                label: i,
                value: i
            })
        }
        setDifficultyLevel(data);
    }

    const handleMultiMediaUpload = async (e: any) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);
        await ViewService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                props.setQuestionData({ ...props.questionData, [e.target.name]: res.data })
                toast.success("Upload Done!")
            }
        }).catch(e => {
            toast.error("Something went wrong when uploading Multimedia");
        })
    }


    const handleOptionSelectionChange = (e: any) => {
        props.setQuestionData({ ...props.questionData, optionType: e.value })
    }

    const handleUpdateOptions = (e: any, index: number) => {
        let currentOptionsData = props.questionData.options;
        currentOptionsData[index].value = e.target.value;
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }

    const addOption = () => {
        let currentOptionsData = props.questionData.options || [];
        currentOptionsData.push({});
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }

    const deleteOption = () => {
        let currentOptionsData = props.questionData.options || [];
        currentOptionsData.pop();
        props.setQuestionData({ ...props.questionData, options: currentOptionsData });
    }


    useEffect(() => {
        if (props.questionData.subjectId?._id || props.questionData?.topicId?._id) {
            getTopicsAndSubTopics(props.questionData?.subjectId?._id, props.questionData?.topicId?._id);
        }

    }, [props.questionData?.subjectId, props.questionData?.topicId])

    useEffect(() => {
        getAllQuestionTypes()
        getAllSubjects()
        getLanguage()
        getDifficultyLevel()
        getQuestionById();
        getExamCategory();
    }, [])

    useEffect(() => {
        if (questionData && questionData.image) {
            getEncMediaImage();
        }
        if (questionData && questionData.video) {
            getEncMediaVideo();
        }
        if (questionData && questionData.audio) {
            getEncMediaAudio();
        }

    }, [props.questionData])

    useEffect(() => {
        if (props.questionData.questionType === 'boolean') {
            props.setQuestionData({ ...props.questionData, questionType: "boolean", options: [{ value: true }, { value: false }] });
        }
    }, [props.questionData.questionType])

    const handleSaveQuestion = () => {
        props.saveQuestion();
    }


    const renderOptionsData = () => {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <div></div>
                    <div>
                        {props.questionData.questionType != "boolean" && props.questionData.options ?
                            <>
                                <FontAwesomeIcon icon={faPlus} className='text-success me-2 xcn-link-pointer' size="sm" onClick={addOption} />
                                <FontAwesomeIcon icon={faMinus} className='text-danger xcn-link-pointer' size="sm" onClick={deleteOption} />
                            </> : " "}
                    </div>
                </div>
                <div>
                    {props.questionData && props.questionData.options && props.questionData.options.map((data: any, index: number) => {
                        return (
                            <div className="d-flex">
                                <div className="me-3">
                                    <Form.Check className="fs-3" checked={props.correctAnswer.includes(index)} onChange={(e: any) => handleCorrectAnswer(e.target.checked, index)} />
                                </div>
                                <FloatingLabel
                                    controlId={"option" + index}
                                    label={"Option " + (index + 1)}
                                    className="w-100 mb-3"
                                >
                                    <Form.Control type="text" value={data.value} onChange={(e: any) => handleUpdateOptions(e, index)} disabled={props.questionData.questionType == "boolean" ? true : false} />
                                </FloatingLabel>
                            </div>

                        )
                    })}
                </div>
            </>
        )
    }

    const renderOptionTypes = () => {
        switch (props.questionData.questionType) {
            case "mcq": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum?.CHECKBOX,
                            label: OptionTypeEnum?.CHECKBOX?.toUpperCase()
                        }, {
                            value: OptionTypeEnum?.BULLET,
                            label: OptionTypeEnum?.BULLET?.toUpperCase()
                        }]}
                        onChange={handleOptionSelectionChange}
                        value={{
                            value: props.questionData.optionType,
                            label: props.questionData.optionType?.toUpperCase(),
                        }}
                    />
                )
            }
            case "descriptive": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum?.TEXTAREA,
                            label: OptionTypeEnum?.TEXTAREA?.toUpperCase()
                        }]}
                        onChange={handleOptionSelectionChange}
                        value={{
                            value: props.questionData.optionType,
                            label: props.questionData.optionType?.toUpperCase(),
                        }}
                    />
                )
            }
            case "comprehension":
            case "video":
            case "fill_in_the_blanks":
            case "audio": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum?.TEXTAREA,
                            label: OptionTypeEnum?.TEXTAREA?.toUpperCase(),
                        }, {
                            value: OptionTypeEnum?.CHECKBOX,
                            label: OptionTypeEnum?.CHECKBOX?.toUpperCase()
                        }, {
                            value: OptionTypeEnum?.BULLET,
                            label: OptionTypeEnum?.BULLET?.toUpperCase()
                        }]}
                        onChange={handleOptionSelectionChange}
                        value={{
                            value: props.questionData.optionType,
                            label: props.questionData.optionType?.toUpperCase(),
                        }}
                    />
                )
            }
            case "boolean": {
                return (
                    <Select
                        options={[{
                            value: OptionTypeEnum?.BOOLEAN,
                            label: "True/False"
                        }]}
                        onChange={handleOptionSelectionChange}
                        value={{
                            value: OptionTypeEnum?.BOOLEAN,
                            label: "True/False"
                        }}
                    />
                )
            }
        }

    }



    const getCurrentSubTopicValue = () => {
        const currentSubTopic = props.questionData?.topicId?.sub_topics?.find((topic: any) => topic._id === props.questionData?.subTopicId)
        return {
            label: currentSubTopic?.name,
            value: currentSubTopic?._id,
        }
    }

    const handleExplanationDelete = (mediaType: string, index: number) => {
        const updatedExplanations = { ...props.questionData.explanation };

        updatedExplanations[mediaType].splice(index, 1);

        if (updatedExplanations[mediaType].length === 0) {
            delete updatedExplanations[mediaType];
        }

        props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
    };



    console.log(props.questionData, "data.................")

    const handleExplanationData = async (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const files = e.target.files;

        if (files) {
            let updatedExplanations = props.questionData.explanation ? { ...props.questionData.explanation } : {};

            if (!updatedExplanations[name]) {
                updatedExplanations[name] = [];
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                let formData = new FormData();
                formData.append('file', file);

                await toast.promise(
                    UploadService.uploadMultimedia(formData).then((res) => {
                        if (res.status === 200) {
                            updatedExplanations[name].push(res.data);
                        }
                    }),
                    {
                        loading: 'Uploading',
                        success: 'Uploaded',
                        error: 'Something went wrong when uploading Multimedia',
                    }
                );
            }

            props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
        }
    };

    const handleTextExplanationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedExplanations = { ...props.questionData.explanation };

        updatedExplanations.text[0] = e.target.value;

        props.setQuestionData({ ...props.questionData, explanation: updatedExplanations });
    };





    return (
        <>
            <PreviewModal
                show={showImageModal}
                onHide={() => setShowImageModal(false)}
                type="image"
                image={encData}
            />

            <PreviewModal
                show={showVideoModal}
                onHide={() => setShowVideoModal(false)}
                type="video"
                video={encVideo}
            />
            <PreviewModal
                show={showAudioModal}
                onHide={() => setShowAudioModal(false)}
                type="audio"
                audio={encAudio}
            />
            <Card className="reg-card box-shadow">
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Type</Form.Label>
                            <Select
                                options={questionTypes}
                                onChange={(e: any) => {
                                    const change = HandleChangeHelper.reactSelectHandleChange("questionType", e.value);
                                    handleQuestionDataChange(change);
                                }}
                                value={{ value: props.questionData.questionType, label: props.questionData.questionType }}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Subject</Form.Label>
                            <Select
                                options={subjects}
                                onChange={(e: any) => {
                                    props.setQuestionData({ ...props.questionData, "subjectId": e.value });
                                }}
                                value={[
                                    {
                                        label: props.questionData.subjectId?.name,
                                        value: props.questionData.subjectId?._id,
                                    }
                                ]}
                            />
                        </Col>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Topic</Form.Label>
                            <Select
                                options={topicsData}
                                onChange={(e: any) => {
                                    props.setQuestionData({ ...props.questionData, "topicId": e });
                                }}
                                value={[{ label: props.questionData.topicId?.name, value: props.questionData.topicId?._id }]}
                            />
                        </Col>

                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Question Sub-Topic</Form.Label>
                            <Select
                                options={subTopicData}
                                onChange={(e: any) => {
                                    props.setQuestionData({ ...props.questionData, "subTopicId": e.value });
                                }}
                                value={getCurrentSubTopicValue()}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="reg-card box-shadow mt-3">
                <Card.Body>
                    <Row>
                        <Col md={6} lg={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Option Type</Form.Label>
                            {renderOptionTypes()}
                        </Col>
                        <Col md={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Question Difficulty Level</Form.Label>
                            <Select
                                options={difficultyLevel}
                                onChange={(e: any) => props.setQuestionData({ ...props.questionData, difficulty: e.value })}
                                value={{
                                    value: props.questionData.difficulty,
                                    label: props.questionData.difficulty
                                }}
                            />
                        </Col>


                        <Col md={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Select Language</Form.Label>
                            <Select
                                options={languages}
                                onChange={(e: any) => props.setQuestionData({ ...props.questionData, language: e.value })}
                                value={{
                                    value: props.questionData.language,
                                    label: props.questionData.language
                                }}
                            />
                        </Col>


                        <Col md={3}>
                            <Form.Label className="xcn-text-12 text-secondary">Exam Category</Form.Label>
                            <Select
                                options={examCategory}
                                onChange={(e: any) => {
                                    const change = HandleChangeHelper.reactSelectHandleChange("examCategory", e.value);
                                    handleQuestionDataChange(change);
                                }}
                                // value={{ value: props.questionData.examCategory, label: props.questionData.examCategory }}
                                value={examCategory?.find((data: any) => data.value === props.questionData.examCategory)}
                            />
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            {props.questionData?.questionType === "comprehension" ? <EditComprehension questionData={props.questionData} setQuestionData={props.setQuestionData} compCorrectAnswer={props.compCorrectAnswer} setCompCorrectAnswer={props.setCompCorrectAnswer} /> :
                <div>
                    <Card className="reg-card box-shadow mt-3">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <div className="d-flex">
                                                <Form.Label className="ms-2 text-primary" style={{ fontSize: "500" }}>Upload Question Text</Form.Label>
                                                <Form.Check className="ms-2" onChange={(e: any) => setToggleQuestion(e.target.checked)} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                {toggleQuestion &&
                                    <Row>
                                        <Col md={12} className='mb-2'>
                                            <Form.Label className="xcn-text-12 text-secondary">Question</Form.Label><span className="text-danger fw-bold xcn-text-20">*</span>
                                            <Form.Control as="textarea" rows={3} onChange={(e: any) => props.setQuestionText(e.target.value)} />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md={4}>
                                        <Form.Label className="xcn-text-12 text-secondary d-flex justify-content-between align-items-center">
                                            <div>
                                                Question Image
                                                {questionData?.image &&
                                                    <>
                                                        <span className="text-success xcn-text-10 ms-2 fw-bold">
                                                            <FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                        </span>
                                                        <span className="text-muted xcn-text-10 ms-2 fw-bold" onClick={() => setShowImageModal(true)}>
                                                            <FontAwesomeIcon icon={faEye} /> Preview
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </Form.Label>
                                        <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={handleMultiMediaUpload} />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label className="xcn-text-12 text-secondary d-flex justify-content-between align-items-center">
                                            <div>
                                                Question Video

                                                {questionData?.video && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold" onClick={() => setShowVideoModal(true)}>
                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                    </span></>}

                                            </div>
                                        </Form.Label>
                                        <Form.Control type="file" name="video" accept='.mp4, .mov' onChange={handleMultiMediaUpload} />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label className="xcn-text-12 text-secondary d-flex justify-content-between align-items-center">
                                            <div>
                                                Question Audio

                                                {questionData?.audio && <><span className="text-success xcn-text-10 ms-2 fw-bold"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded
                                                </span> <span className="text-muted xcn-text-10 ms-2 fw-bold" onClick={() => setShowAudioModal(true)}>
                                                        <FontAwesomeIcon icon={faEye} /> Preview
                                                    </span></>}
                                            </div>
                                        </Form.Label>
                                        <Form.Control type="file" name="audio" accept=".mp3, .aac, .wav" onChange={handleMultiMediaUpload} />
                                    </Col>
                                </Row>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="reg-card box-shadow mt-3">
                        <Card.Body>
                            <div>
                                {
                                    props.questionData.questionType &&
                                    <Row>
                                        <Col md={8}>
                                            <h6 className="mt-2 fw-bold text-secondary">Option Data</h6>
                                            {renderOptionsData()}
                                        </Col>
                                    </Row>
                                }
                            </div>
                            {props.questionData?.explanation &&
                                <div className="mt-3">
                                    <h5 className="mt-3 mb-2">Explanation</h5>
                                    <Row>
                                        <Col>
                                            <Card className="px-2 py-1 mb-2 me-4" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                                <Card.Body>
                                                    <Form.Group>
                                                        <Form.Label>Explanation Text</Form.Label>
                                                        <Form.Control
                                                            className="w-50"
                                                            as="textarea"
                                                            rows={10}
                                                            cols={10}
                                                            defaultValue={props.questionData?.explanation && props.questionData?.explanation.text && props.questionData?.explanation?.text[0]}
                                                            onChange={(e: any) => handleTextExplanationChange(e)}
                                                        />
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Row>
                                            <Row>
                                                <Col>
                                                    <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                                        {props.questionData?.explanation?.audio?.length > 0 ? props.questionData?.explanation?.audio?.map((data: any, index: number) => {
                                                            return (
                                                                <div>
                                                                    <Row>
                                                                        <Col key={index}>
                                                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                                                <Form.Label className="xcn-text-12 text-secondary">
                                                                                    Audio {index + 1}
                                                                                </Form.Label>
                                                                                <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashAlt}
                                                                                        className="ms-2 text-danger"
                                                                                        onClick={() => handleExplanationDelete("audio", index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                </div>
                                                            )
                                                        }) : "No data found!!!"}
                                                    </Card>
                                                    <Col>
                                                        <Form.Label>Upload Audio</Form.Label>
                                                        <Form.Control type="file" name="audio" accept="audio/*" multiple onChange={(e: any) => handleExplanationData(e, "audio")} />
                                                    </Col>
                                                </Col>
                                                <Col>
                                                    <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                                        {props.questionData?.explanation?.image?.length > 0 ? props.questionData?.explanation?.image?.map((data: any, index: number) => {
                                                            return (
                                                                <div>
                                                                    <Row>
                                                                        <Col key={index}>
                                                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                                                <Form.Label className="xcn-text-12 text-secondary">
                                                                                    Image {index + 1}
                                                                                </Form.Label>
                                                                                <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashAlt}
                                                                                        className="ms-2 text-danger"
                                                                                        onClick={() => handleExplanationDelete("image", index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        }) : "No data found!!!"}
                                                    </Card>
                                                    <Col>
                                                        <Form.Label>Upload Image</Form.Label>
                                                        <Form.Control type="file" name="image" accept="image/png, image/jpeg" multiple onChange={(e: any) => handleExplanationData(e, "image")} />
                                                    </Col>
                                                </Col>
                                                <Col>
                                                    <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                                        {props.questionData?.explanation?.video?.length > 0 ? props.questionData?.explanation?.video?.map((data: any, index: number) => {
                                                            return (
                                                                <div>
                                                                    <Row>
                                                                        <Col key={index}>
                                                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                                                <Form.Label className="xcn-text-12 text-secondary">
                                                                                    Video {index + 1}
                                                                                </Form.Label>
                                                                                <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashAlt}
                                                                                        className="ms-2 text-danger"
                                                                                        onClick={() => handleExplanationDelete("video", index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        }) : "No data found!!!"}
                                                    </Card>
                                                    <Col>
                                                        <Form.Label>Upload Video</Form.Label>
                                                        <Form.Control type="file" name="video" accept="video/*" multiple onChange={(e: any) => handleExplanationData(e, "video")} />
                                                    </Col>
                                                </Col>
                                                <Col>
                                                    <Card className="px-2 py-1 mb-2" style={{ backgroundColor: "#D0D5DD60", borderRadius: 6, outline: "none", border: "none" }}>
                                                        {props.questionData?.explanation?.doc?.length > 0 ? props.questionData?.explanation?.doc?.map((data: any, index: number) => {
                                                            return (
                                                                <div>
                                                                    <Row>
                                                                        <Col key={index}>
                                                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                                                <Form.Label className="xcn-text-12 text-secondary">
                                                                                    Doc {index + 1}
                                                                                </Form.Label>
                                                                                <div className="text-muted xcn-text-10 ms-2 fw-bold">
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashAlt}
                                                                                        className="ms-2 text-danger"
                                                                                        onClick={() => handleExplanationDelete("doc", index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        }) : "No data found!!!"}
                                                    </Card>
                                                    <Col>
                                                        <Form.Label>Upload PDF</Form.Label>
                                                        <Form.Control type="file" name="doc" accept=".pdf" multiple onChange={(e: any) => handleExplanationData(e, "doc")} />
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card >
                </div>
            }
            <div className="d-flex justify-content-center align-items-center mt-3">
                <Button variant="primary" size="sm"
                    onClick={() => handleSaveQuestion()}
                >
                    <FontAwesomeIcon icon={faFloppyDisk} /> Save Question and Send for Re-verification
                </Button>
            </div>

        </>
    )
}