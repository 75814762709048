import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export class ExamCategoryService{
    static async getAllCompanyExamCategories(){
        return await makeRequest(urls.examCategory.getAllComapnyExamCategories, "GET");
    }

    static async deleteExamCategory(id: any){
        return await makeRequest(urls.examCategory.deleteExamCategory+"/"+id, "DELETE");
    }

    static async addExamCategory(data: any){
        return await makeRequest(urls.examCategory.addExamCategory, "POST", data);
    }

    static async editExamCategory(id: any, data: any){
        return await makeRequest(urls.examCategory.editExamCategory+"/"+id, "PUT", data);
    }
}