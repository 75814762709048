import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBackward, faEdit, faStepBackward } from "@fortawesome/free-solid-svg-icons";
import SubjectService from "../../services/subjects.service";
import AddTopicModal from "../../Modal/AddTopic.modal";
import EditTopicModal from "../../Modal/EditTopic.modal";

export default function SubjectById() {

    const navigate = useNavigate();

    const { id }: any = useParams();

    const [subject, setSubject] = useState<any>({});
    const [showAddTopicModal, setShowAddTopicModal] = useState<boolean>(false)
    const [topicIndex, setTopicIndex] = useState<number>(-1);


    const getSubjectDetails = async () => {
        await SubjectService.getSubjectById(id).then(res => {
            if (res.status === 200) {
                setSubject(res.data);
            }
        })
    }







    useEffect(() => {
        getSubjectDetails();
    }, [])

    return (
        <>
            <div>
                <span onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <h6>Subject Name: {subject?.name || "--"}</h6>
            </div>



            <Row className="mt-3">
                <Col md="9">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Topics</h5>
                        <Button size="sm" variant="secondary" onClick={() => setShowAddTopicModal(true)} >Add Topic</Button>
                    </div>
                    {subject && subject.topics && subject.topics.length > 0 ? subject.topics.map((data: any, index: number) => {
                        return (
                            <div className="mb-4">
                                <Card style={{ backgroundColor: "#D0D5DD60", outline: "none", border: "none" }}>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <span>Topic:</span>
                                                <span style={{ fontWeight: "500" }}>{" "} {data?.name || "--"}</span>
                                            </div>
                                            <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} onClick={() => setTopicIndex(index)} />
                                            {/* <Button size="sm" variant="secondary" onClick={() => setEditTopic(data._id)}>Add Sub Topics</Button> */}
                                        </div>
                                        <div className="mt-3">
                                            <h6>Sub Topics</h6>
                                            <div className="d-flex flex-wrap">
                                                {data && data.sub_topics && data.sub_topics.length > 0 ? data.sub_topics.map((data: any) => {
                                                    return (
                                                        <div className=" me-2">
                                                            <h6>
                                                                <Badge bg="secondary">{data?.name || "--"}</Badge>
                                                            </h6>
                                                        </div>

                                                    )
                                                }) : <span className="text-muted fw-muted">
                                                    No sub-topic found!
                                                </span>
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    }) : "No topic added"}
                </Col>
            </Row>

            <AddTopicModal
                show={showAddTopicModal}
                handleClose={() => {
                    setShowAddTopicModal(false);
                    getSubjectDetails();
                }}
            />


            <EditTopicModal
                show={topicIndex >= 0 ? true : false}
                handleClose={() => {
                    setTopicIndex(-1);
                    getSubjectDetails();
                }}
                data={subject && subject.topics && subject.topics[topicIndex]}
            />
        </>
    )
}