import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import SubjectService from "../services/subjects.service";
import SideModal from "../components/SideModal/SideModal";

interface IEditSubject {
    subjectId: string
    setShow: any
}

export default function EditSubject(props: IEditSubject) {

    const [subject, setSubject] = useState<any>({});

    const getSubjectDetails = async() => {
        await SubjectService.getSubjectById(props.subjectId).then(res => {
            if (res.status === 200) {
                setSubject(res.data);
            }
        })
    }

    const handleTopicChange = (index:number, e:any) => {
        const newtopics = subject.topics;
        newtopics[index].name = e.target.value;
        setSubject({...subject, topics: newtopics});
    }


    const addTopic = () => {
        const oldtopics = subject.topics;
        oldtopics.push({
            name: ""
        })
        setSubject({...subject, topics: oldtopics});
    }

    const deleteTopic = (topicIndex:number) => {
        let oldtopics = subject.topics;
        oldtopics.splice(topicIndex, 1);
        setSubject({...subject, topics: oldtopics});
    }


    const saveSubject = async () => {
        const saveObj = {
            name: subject.name,
            topics: subject.topics.map((data:any)=>{
                return {
                    name: data.name,
                    _id: data._id
                }
            })
        }

        await SubjectService.updateSubject(subject._id, saveObj).then(res=>{
            if(res.status === 200){
                toast.success("Saved")
                props.setShow()
            }
        });
    }

    useEffect(() => {
        if(props.subjectId){
            getSubjectDetails();
        }
    }, [props.subjectId])

    return (
        <>
            <SideModal
                show={props.subjectId ? true : false}
                handleClose={props.setShow}
                body={<>
                    {
                        subject && subject.topics && subject.topics.length > 0 && subject.topics.map((topic:any, index: number)=>{
                            return (<>
                            <Row className="mt-2 align-items-center">
                                <Col md={10}>
                                <Form.Control
                                type="text"
                                value={topic.name}
                                onChange={(e)=>handleTopicChange(index, e)}
                                />
                                </Col>
                                <Col md={2}>
                                    <FontAwesomeIcon icon={faClose} className="text-danger" onClick={()=>deleteTopic(index)}/>
                                </Col>  
                            </Row>
                            </>)
                        })
                    }
                    <div className="d-flex justify-content-end mt-4"> 
                    <Button size="sm"  onClick={addTopic}> Add New Topic </Button>
                    </div>

                </>}

                title="Edit Subject"
                footer={<>
                        <Button  onClick={saveSubject}> Save </Button>
                </>}
            />
        </>
    )
}