import { Badge, Card, Dropdown } from "react-bootstrap";
import CustomToggle from "../../Menu/CustomMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsisVertical, faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import ViewService from "../../services/view.service";
import { toast } from "react-hot-toast";
import PreviewImageModal from "../../Modal/PreviewImage.modal";
import { useState } from "react";
import moment from "moment";
import CardTable from "./CardTable";
import QuestionCardLoader from "../../components/Loaders/QuestionCard.loader";
import TablePagination from "../../components/Pagination/Table.paginaition";

interface IQuestionCard {
    data: any,
    reload?: any,
    loading?: boolean
}

export default function QuestionCard(props: IQuestionCard) {

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);

    if (props.loading) {
        return (
            <>
                <QuestionCardLoader />
                <QuestionCardLoader />
                <QuestionCardLoader />
            </>
        )
    }

    return (
        <>
            {props.data && props.data.length > 0 ? props.data.map((data: any) => {
                return (
                    <Card className="mt-3 shadow-lg">
                        <Card.Body>
                            <Card className="shadow-sm">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Difficulty:</p>
                                            <p className="ms-2">{data?.questionData?.difficulty}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Frequency:</p>
                                            <p className="ms-2">{data?.questionData?.frequencyUsed}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Question type:</p>
                                            <p className="ms-2">{data?.questionData?.questionType}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Option Type:</p>
                                            <p className="ms-2">{data?.questionData?.optionType}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fw-bold">Exam Category:</p>
                                            <p className="ms-2">{data?.questionData?.examCategory?.name || "--"}</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <CardTable
                                tableData={data.questionLanguage}
                                reload={props.reload}
                                questionId={data?.questionData?._id}
                            />
                        </Card.Body >
                    </Card >
                )
            }) :
                <>
                    <p className="text-center">No Data Found</p>
                </>
            }
        </>
    )
}