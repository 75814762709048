import { Button, Form, Modal } from "react-bootstrap";
import BasicModal from "./Basic.modal";
import Select from "react-select"
import { useEffect, useState } from "react";
import PublicService from "../../services/public.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { EnumConverterHelpher } from "../helpher/EnumConverter.helpher";
import UserService from "../../services/user.service";
import toast from "react-hot-toast";


interface ILanguageModal {
    show: any,
    handleClose: any,
    reload: any
}

export default function LanguageModal(props: ILanguageModal) {


    const [language, setLanguage] = useState<any>();
    const [translatedLanguage, setTranslatedLanguage] = useState<any>();


    const getTranslatedLanguage = async () => {
        await UserService.getTranslatedLanguage(props.show).then((res) => {
            if (res.status === 200) {
                setTranslatedLanguage(res.data.translatorlanguages);
            }
        })
    }


    const getAllLanguages = async () => {
        await PublicService.getLanguage().then((res) => {
            if (res.status === 200) {
                setLanguage(EnumConverterHelpher.EnumConverter(res.data).map((data: any) => {
                    return {
                        label: data.value,
                        value: data.value,
                    }
                }))
            }
        })
    }





    const old_language = translatedLanguage && translatedLanguage.map((lang: any) => {
        const allowed = language.filter((data: any) => data.value === lang);
        if (allowed?.length > 0) {
            return {
                label: lang,
                value: lang
            }
        }
    })
    const [updatedLanguage, setUpdatedLanguage] = useState<any>(old_language);





    const handleAddLanguage = async (e: any) => {
        const payload = {
            language: updatedLanguage && updatedLanguage.map((data: any) => data.label)
        }
        await UserService.addLanguage(props.show, payload).then((res) => {
            if (res.status) {
                toast.success("Language added successfully")
                props.handleClose(undefined)
                props.reload();
            }
        })
    }


    useEffect(() => {
        if (props.show) {
            getTranslatedLanguage();
        }

    }, [props.show])





    useEffect(() => {
        getAllLanguages();
    }, [])

    return (
        <Modal show={props.show ? true : false}>
            <div className='d-flex justify-content-between align-items-center m-3'>
                <div>
                    Add Languages
                </div>
                <FontAwesomeIcon className='xcn-link-pointer' icon={faXmark} onClick={() => props.handleClose(undefined)} />
            </div>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Select Language</Form.Label>
                    <Select options={language} value={updatedLanguage ? updatedLanguage : old_language} onChange={(e: any) => setUpdatedLanguage(e)} isMulti />
                </Form.Group>

                <Button className="mt-3" size="sm" onClick={handleAddLanguage}>Save</Button>
            </Modal.Body>
        </Modal>
    )
}