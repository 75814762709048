import { faPencil, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Image, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import QAService from '../../services/qa.service'
import QuestionsData from './QuestionsData';
import Remarks from './Remarks';
import { BsArrowLeft } from 'react-icons/bs';
import Select from "react-select"
import QALoader from '../../components/Loaders/QA.loader';
import ViewComprehensions from '../../components/Comprehension/Custom.Comprehension';

const ViewNotVerifiedQuestion = () => {

    const params: any = useParams();
    const navigate = useNavigate();
    const tab = localStorage.getItem("qaValue")

    const [questionData, setQuestionData] = useState<any>();
    const [difficultyLevel, setDifficultyLevel] = useState<any[]>();
    const [edit, setEdit] = useState<boolean>(false);
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);



    const getQuestionData = async () => {
        setLoading(true);
        await QAService.getQuestionbyQuestionId(params.questionId).then((res) => {
            if (res.status === 200) {
                setQuestionData(res.data)
            }
        }).catch(err => {
            toast.error(err.response.data)
        }).finally(() => setLoading(false))
    }

    const getDifficultyLevel = () => {
        let data = []
        for (let i = 1; i <= 10; i++) {
            data.push({
                label: i,
                value: i
            })
        }
        setDifficultyLevel(data);
    }




    const updateQuestionDifficulty = async () => {
        await QAService.updateQuestionDifficulty(params.questionId, { difficulty: selectedDifficultyLevel }).then((res) => {
            if (res.status === 200) {
                getQuestionData();
                setEdit(false)
                toast.success("Difficulty Updated")
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }



    useEffect(() => {
        getQuestionData();
        getDifficultyLevel();
    }, [])

    return (
        <>

            <span
                className='d-flex align-items-center xcn-text-15  mb-3'
                onClick={() => window.history.back()}
                style={{ cursor: 'pointer' }}
            >
                <BsArrowLeft /> {" "}
                <span className='fw-bold xcn-text-15 text-muted'>Back</span>
            </span>
            {
                loading ?
                    <QALoader /> :
                    <Row>
                        <Col md={8}>
                            <Card className="reg-card box-shadow mt-3 mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            {edit ? <div className="d-flex align-items-center">
                                                <span className='fw-bold xcn-text-15 text-muted'>Difficulty: </span>
                                                <div className="ms-2" style={{ width: "50%" }}>
                                                    <Select
                                                        value={selectedDifficultyLevel ? [{ label: selectedDifficultyLevel, value: selectedDifficultyLevel }] : [{ label: questionData?.dificulty, value: questionData?.dificulty }]}
                                                        options={difficultyLevel}
                                                        onChange={(e: any) => setSelectedDifficultyLevel(e.value)}
                                                    />
                                                </div>
                                                <Button size="sm" variant="secondary" className="ms-2 py-2 px-3" onClick={() => updateQuestionDifficulty()} >Save</Button>
                                            </div> :
                                                <div className="d-flex">
                                                    <span className='fw-bold xcn-text-15 text-muted'>Difficulty: </span>
                                                    <span className='fw-bold xcn-text-15 ms-2'>{questionData && questionData.dificulty ? questionData.dificulty : "0"}</span>
                                                    <FontAwesomeIcon icon={faPencil} className='fw-bold text-muted xcn-text-15 ms-2 text-center' style={{ cursor: "pointer" }} onClick={() => setEdit(true)} />
                                                </div>
                                            }

                                            <div>
                                                <span className='fw-bold xcn-text-15 text-muted'>Frequency Used: </span>
                                                <span className='fw-bold xcn-text-15'>{questionData && questionData.frequencyUsed ? questionData.frequencyUsed : "0"}</span>
                                            </div>
                                            <div>
                                                <span className='fw-bold xcn-text-15 text-muted'>Subject: </span>
                                                <span className='fw-bold xcn-text-15'>{questionData && questionData.subject ? questionData.subject : "--"}</span>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div>
                                                <span className='fw-bold xcn-text-15 text-muted'>Language: </span>
                                                <span className='fw-bold xcn-text-15'>{questionData && questionData.language ? questionData.language : "--"}</span>
                                            </div>
                                            <div>
                                                <span className='fw-bold xcn-text-15 text-muted'>Option Type: </span>
                                                <span className='fw-bold xcn-text-15'>{questionData && questionData.optionType ? questionData.optionType : "--"}</span>
                                            </div>
                                            <div>
                                                <span className='fw-bold xcn-text-15 text-muted'>Question Type: </span>
                                                <span className='fw-bold xcn-text-15'>{questionData && questionData.questionType ? questionData.questionType : "--"}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <div>
                                    <span className='fw-bold xcn-text-15'>SubjectId: </span>
                                    <span className='fw-bold xcn-text-15'>{questionData && questionData.subjectId ? questionData.subjectId : "--"}</span>
                                </div> */}

                                </Card.Body>
                            </Card>
                            {questionData && questionData.questionType === "comprehension" ? <ViewComprehensions data={questionData} /> :
                                <QuestionsData questionData={questionData} />
                            }
                        </Col>
                        <Col md={4}>
                            <Remarks questionData={questionData} reload={getQuestionData} />
                        </Col>
                    </Row >
            }
        </>
    )
}

export default ViewNotVerifiedQuestion