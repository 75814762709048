import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class ApiKeyService{
    static async apikeysOfCompany(){
        return makeRequest(urls.apiKey.apikeysOfCompany, "get");
    }
    static async createApiKey(payload:any){
        return makeRequest(urls.apiKey.createApikey, "post",payload);
    }
    static async toggleApiKey(id:any){
        return makeRequest(urls.apiKey.toggleApiKey+'/'+id  , "put");
    }
}