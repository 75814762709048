import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';
import PublicService from '../../services/public.service';
import Delete from "../../assets/images/delete.svg";
import UserProfileService from '../../services/userprofile.service';
import QPModal from './Modal';
import { OptionTypeEnum } from '../../enums/question.enum';
import QP_GenerationService from '../../services/qp_generation.service';
import Select from 'react-select';
import ConfirmationModal from './ConfirmationModal';

function TopicTabs({ subjects }) {

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const [key, setKey] = useState('');
    let [difficulty, setDifficulty] = useState(5);
    let [noOfQuestions, setNoOfQuestions] = useState(0);
    let [selectedTopicCreds, setSelectedTopicCreds] = useState("");
    let [selectedQuestionType, setSelectedQuestionType] = useState("");
    let [selectedLanguage, setSelectedLanguage] = useState();
    let [selectedOptionType, setSelectedOptionType] = useState("");
    let [selectedSections, setSelectedSections] = useState([]);
    let [languages, setLanguages] = useState([]);
    let [qTypes, setQTypes] = useState([]);
    let [opTypes, setOpTypes] = useState([]);
    let [finalArr, setFinalArr] = useState([]);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    let [qpDets, setQpDets] = useState([]);

    const getLanguage = async () => {
        await PublicService.getLanguage().then(res => {
            if (res.status === 200) {
                const language = Object.values(res.data).map((data) => {
                    return {
                        label: data,
                        value: data
                    }
                })
                setLanguages(language);
            }
        })
    }

    const generate_qp = async (arr) => {
        await QP_GenerationService.checkQuestionAvailability({ questionsData: arr }).then(res => {
            let result = res.data.finalData;
            let temp = [];
            for (let obj of result) {
                let subjectId = obj.subjectId;
                for (let i of obj.sectionData) {
                    temp.push({
                        subjectId: subjectId,
                        topicId: i.topicId,
                        availability: i.available
                    })
                }
            }
            let arr_new = [];
            for (let i of selectedSections) {
                for (let j of temp) {
                    if (i.subjectId === j.subjectId && i.topicId === j.topicId) {
                        let ele = i;
                        ele.availability = j.availability;
                        arr_new.push(ele);
                    }
                }
            }
            console.log(arr_new);
            setQpDets(arr_new);
        })
    }

    const getQType = async () => {
        await UserProfileService.getAllQuestionTypes().then(res => {

            const qtypes_arr = Object.values(res.data).map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
            setQTypes(qtypes_arr);
        })
    }

    // const handleOptionSelectionChange = (e: any) => {
    //     setSelectedQuestionType(e)
    //     if(selectedQuestionType == "boolean"){
    //         ({ optionType: e.value, optionsData: ["True", "False"] });
    //     }
    //     else{
    //         setSelectedOptionData({ optionType: e.value, optionsData: [] });
    //     }
    // }

    const renderOptionTypes = () => {

        switch (selectedQuestionType) {
            case "mcq": {
                return (
                    <select className='form-control'
                        value={selectedOptionType}
                        onChange={(e) => {
                            setSelectedOptionType(e.target.value);
                        }}
                    >
                        <option
                            name={""}
                            value={""}>
                            Select
                        </option>
                        <option
                            name={OptionTypeEnum.CHECKBOX}
                            value={OptionTypeEnum.CHECKBOX}>
                            {OptionTypeEnum.CHECKBOX}
                        </option>

                        <option
                            name={OptionTypeEnum.BULLET}
                            value={OptionTypeEnum.BULLET}>
                            {OptionTypeEnum.BULLET}
                        </option>
                    </select>
                )
            }
            case "descriptive": {
                return (

                    <select className='form-control'
                        value={selectedOptionType}
                        onChange={(e) => {
                            setSelectedOptionType(e.target.value);
                        }}
                    >
                        <option
                            name={""}
                            value={""}>
                            Select
                        </option>
                        <option
                            name={OptionTypeEnum.TEXTAREA}
                            value={OptionTypeEnum.TEXTAREA}>
                            {OptionTypeEnum.TEXTAREA}
                        </option>
                    </select>
                )
            }
            case "comprehension":
            case "video":
            case "fill_in_the_blanks":
            case "audio": {
                return (

                    <select className='form-control'
                        value={selectedOptionType}
                        onChange={(e) => {
                            setSelectedOptionType(e.target.value);
                        }}
                    >
                        <option
                            name={""}
                            value={""}>
                            Select
                        </option>
                        <option
                            name={OptionTypeEnum.TEXTAREA}
                            value={OptionTypeEnum.TEXTAREA}>
                            {OptionTypeEnum.TEXTAREA}
                        </option>
                        <option
                            name={OptionTypeEnum.CHECKBOX}
                            value={OptionTypeEnum.CHECKBOX}>
                            {OptionTypeEnum.CHECKBOX}
                        </option>
                        <option
                            name={OptionTypeEnum.BULLET}
                            value={OptionTypeEnum.BULLET}>
                            {OptionTypeEnum.BULLET}
                        </option>
                    </select>
                )
            }
            case "boolean": {
                return (
                    <select className='form-control'
                        value={selectedOptionType}
                        onChange={(e) => {
                            setSelectedOptionType(e.target.value);
                        }}
                    >
                        <option
                            name={""}
                            value={""}>
                            Select
                        </option>
                        <option
                            name={OptionTypeEnum.BOOLEAN}
                            value={OptionTypeEnum.BOOLEAN}>
                            {OptionTypeEnum.BOOLEAN}
                        </option>
                    </select>
                )
            }
        }

    }


    const getOpType = async () => {
        const optypes_arr = Object.values(OptionTypeEnum).map((data) => {
            return {
                label: data,
                value: data
            }
        })
        setOpTypes(optypes_arr);
    }



    useEffect(() => {
        getQType();
        getOpType();
        getLanguage();
    }, []);
    // console.log("selectedSections : ", selectedSections)
    return (<>
        {/* <QPModal toggle={toggle} modal={modal} qpDets={qpDets} finalArr={finalArr} /> */}
        <ConfirmationModal
            setShow={setShowConfirmationModal}
            show={showConfirmationModal}
            reqQuestions={() => {
                let arr = [];
                for (let sub of subjects) {
                    // const obj = [];
                    // let obj = { subjectId: sub._id, sectionData: [] }
                    for (let section of selectedSections) {
                        if (section.subjectId === sub._id) {
                            let sec_obj = {
                                topicId: section.topicId,
                                subjectId: section.subjectId,
                                questionType: section.qType,
                                language: section.language,
                                optionType: section.optionType,
                                difficulty_level: section.difficulty,
                                number_of_questions: section.noOfQuestions
                            }
                            arr.push(sec_obj);
                        }
                    }
                    // arr.push(obj);
                }
                return arr
            }}
        />
        <div className="border border-lg p-3 my-3">
            <div className="d-flex align-items-center justify-content-between">
                <h5 className='mb-3'>Select SubjectWise Topics</h5>

                <div>

                    <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {
                            setShowConfirmationModal(true)

                            // toggle();
                            // let arr = [];
                            // for (let sub of subjects) {
                            //     let obj = { subjectId: sub._id, sectionData: [] }
                            //     for (let section of selectedSections) {
                            //         if (section.subjectId === sub._id) {
                            //             let sec_obj = {
                            //                 topicId: section.topicId,
                            //                 questionTypeEnum: section.qType,
                            //                 questionLanguage: section.language,
                            //                 optionType: section.optionType,
                            //                 difficulty: section.difficulty,
                            //                 number_of_questions: section.noOfQuestions
                            //             }
                            //             obj.sectionData.push(sec_obj);
                            //         }
                            //     }
                            //     arr.push(obj);
                            // }
                            // console.log("FINAL ARR OBJ : ", arr);
                            // // arr will be sent to backend : based on which tick or cross will be displayed

                            // generate_qp(arr);
                            // setFinalArr(arr);

                        }}
                    >
                        Generate Question Paper
                    </button>
                </div>

            </div>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {
                    subjects.map(subject =>
                        <Tab eventKey={subject._id} title={subject.name}>
                            <Table striped bordered hover className="my-3">
                                <thead>
                                    <tr>
                                        {/* <th>Number</th> */}
                                        <th>Topic Name</th>
                                        <th>Question Type</th>
                                        <th>Language</th>
                                        <th>Number Of Questions</th>
                                        <th>Difficulty</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedSections.length === 0 && <div className="p-3">"No sections were created"</div>}

                                    {selectedSections.length > 0 && selectedSections.map((section) => (

                                        section.subjectId === subject._id && <tr key={selectedSections.indexOf(section)}>
                                            {/* <td>{selectedSections.indexOf(section) + 1}</td> */}
                                            <td>{section.topicName}</td>
                                            <td>{section.qType}</td>
                                            <td>{section.language}</td>
                                            <td>{section.noOfQuestions}</td>
                                            <td>{section.difficulty}</td>
                                            <td className="text-center">
                                                {" "}
                                                <img
                                                    src={Delete}
                                                    alt=""
                                                    width="20"
                                                    onClick={() => {
                                                        let obj = selectedSections.filter((sc) => section !== sc);
                                                        setSelectedSections(obj);
                                                    }
                                                    }
                                                />{" "}
                                            </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                            <Row>
                                <Col>
                                    <h6>Topic</h6>
                                    <select className='form-control'
                                        value={selectedTopicCreds}
                                        onChange={(e) => {
                                            setSelectedTopicCreds(e.target.value);
                                        }}
                                    >
                                        <option
                                            name={""}
                                            value={""}>
                                            Select
                                        </option>
                                        {subject.topics.map(topic =>
                                            <option
                                                name={topic.name}
                                                value={topic._id + ',' + topic.name}>
                                                {topic.name}
                                            </option>
                                        )}
                                    </select>
                                </Col>
                                <Col>
                                    <h6>Question Type</h6>
                                    <select className='form-control'
                                        value={selectedQuestionType}
                                        onChange={(e) => {
                                            setSelectedQuestionType(e.target.value);
                                        }}
                                    >
                                        <option
                                            name={""}
                                            value={""}>
                                            Select
                                        </option>

                                        {qTypes.map(type =>
                                            <option
                                                name={type.value}
                                                value={type.value}>
                                                {type.value}
                                            </option>
                                        )}
                                    </select>
                                </Col>
                                <Col>
                                    <h6>Option Type</h6>
                                    {renderOptionTypes()}
                                </Col>
                                <Col>
                                    <h6>Language</h6>
                                    <Select
                                        options={languages}
                                        onChange={(e) => setSelectedLanguage(e)}
                                        isMulti
                                    />
                                    {/* <select className='form-control'
                                        value={selectedLanguage}
                                        onChange={(e) => {
                                            setSelectedLanguage(e.target.value);
                                        }}
                                        multiple
                                    >
                                        <option
                                            name={""}
                                            value={""}>
                                            Select
                                        </option>

                                        {languages.map(language =>
                                            <option
                                                name={language.value}
                                                value={language.value}>
                                                {language.value}
                                            </option>
                                        )}
                                    </select> */}
                                </Col>
                                <Col>
                                    <h6>No of Questions</h6>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Number of Questions"
                                        value={noOfQuestions}
                                        onChange={(e) => {
                                            setNoOfQuestions(parseInt(e.target.value));
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <h6>Difficulty</h6>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Difficulty Level"
                                        value={difficulty}
                                        onChange={(e) => {
                                            if (e.target.value > 10 || e.target.value < 0) toast.error("Difficulty level can only be in [0,10] range");
                                            else setDifficulty(parseInt(e.target.value));
                                        }}
                                    />
                                </Col>

                            </Row>
                            <button className="btn btn-outline-primary my-3"
                                onClick={() => {
                                    if (selectedTopicCreds === "" || noOfQuestions === 0 || selectedQuestionType === "" ||
                                        selectedLanguage?.length === 0) {
                                        toast.error("Please fill the section details properly")
                                        return;
                                    }

                                    const language = selectedLanguage && selectedLanguage.map((data) => { return data.value })

                                    let obj = {
                                        subjectId: subject._id,
                                        subjectName: subject.name,
                                        topicId: selectedTopicCreds.split(',')[0],
                                        topicName: selectedTopicCreds.split(',')[1],
                                        noOfQuestions: noOfQuestions,
                                        difficulty: difficulty,
                                        qType: selectedQuestionType,
                                        language: language,
                                        optionType: selectedOptionType
                                    }
                                    setSelectedSections([...selectedSections, obj]);
                                    setDifficulty(5);
                                    setNoOfQuestions(0);
                                    setSelectedTopicCreds("");
                                    setSelectedLanguage([]);
                                    setSelectedQuestionType("");
                                    setSelectedOptionType("");
                                }
                                }
                            >
                                Add Section
                            </button>
                        </Tab>
                    )
                }
            </Tabs>
        </div>

    </>
    );
}

export default TopicTabs;