import React, { useEffect } from 'react';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ViewService from '../../../services/view.service';
import ViewQuestion from './ViewQuestion';
import { useState } from 'react';
import ViewVerificationIssues from './Verification.issue';
import toast from 'react-hot-toast';
import PreviewModal from '../../../Modal/preview.modal';
import UploadService from '../../../services/upload.service';
import QAService from '../../../services/qa.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

export default function ViewQuestionIndex() {

    const params: any = useParams();

    const [questionData, setQuestionData] = useState<any>({});
    const [questionAttachments, setQuestionAttachements] = useState<any[]>([]);
    const [encData, setEncData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>();
    const [correctAnswer, setCorrectAnswer] = useState<any>([])
    const [compCorrectAnswer, setCompCorrectAnswer] = useState<any>([])
    const [toggleQuestion, setToggleQuestion] = useState<boolean>(false)
    const [questionText, setQuestionText] = useState<any>();

    const getQuestionByQuestionId = async () => {
        await ViewService.getQuestionByQuestionId(params.languageId).then((res: any) => {
            if (res.status === 200) {
                setQuestionData(res.data);
                const newCorrectAnswers = [];
                const newCompCorrectAnswers = [];
                if (res.data?.questionType === "comprehension") {
                    for (const ans of res.data?.questions?.answers) {
                        const ans_index = res.data.questions?.options.findIndex((opt: any) => String(opt._id) === String(ans));
                        if (ans_index >= 0) {
                            newCompCorrectAnswers.push(ans_index);
                        }
                    }
                    setCompCorrectAnswer(newCompCorrectAnswers)
                } else {
                    for (const ans of res.data?.answers) {
                        const ans_index = res.data.options.findIndex((opt: any) => String(opt._id) === String(ans));
                        if (ans_index >= 0) {
                            newCorrectAnswers.push(ans_index);
                        }
                    }
                    setCorrectAnswer(newCorrectAnswers)
                }
            }
        })
    }


    useEffect(() => {
        getQuestionByQuestionId();
    }, [])

    const saveQuestion = async () => {
        if (!params.questionId) return false;

        const explanation_payload = {
            audio: questionData.explanation?.audio,
            video: questionData.explanation?.video,
            image: questionData.explanation?.image,
            doc: questionData.explanation?.doc,
            text: questionData.explanation?.text,
            _id: questionData.explanation?._id
        }


        const questionData_payload = {
            language: questionData.language,
            options: questionData.options,
            image: questionData.image,
            audio: questionData.audio,
            video: questionData.video,
            attachments: questionData.attachments,
            answers: correctAnswer,
            questionText: questionText,
            explanation: explanation_payload,
        }

        const questionDataPayload = {
            topicId: questionData?.value ? questionData?.value : questionData.topicId._id,
            subjectId: questionData.subjectId._id,
            subTopicId: questionData.subTopicId,
            questionType: questionData.questionType,
            examCategory: questionData.examCategory,
            optionType: questionData.optionType,
            difficulty: questionData.difficulty,
            questionData: [questionData_payload]
        };

        toast.promise(
            ViewService.updateQuestion(params.languageId, questionDataPayload).then((res) => {
                toast.success("Question Saved!")
                getQuestionByQuestionId();
            }),
            {
                loading: 'Uploading...',
                success: " Question Upload Successful.",
                error: "Unable to upload this question.",
            }
        );
    }

    const saveCompQuestionData = async () => {
        if (!params.questionId) return false;

        const explanation_payload = {
            audio: questionData.explanation?.audio || [],
            video: questionData.explanation?.video || [],
            image: questionData.explanation?.image || [],
            doc: questionData.explanation?.doc || [],
            text: questionData.explanation?.text || [],
        };

        const compData = {
            language: questionData.language || '',
            instructions: questionData.instructions || '',
            theory: questionData.theory || '',
            explanation: explanation_payload,
            questions: questionData.questions.map((question: any) => {
                const { _id, options, ...rest } = question;
                const answers = compCorrectAnswer;
                const updatedOptions = options?.map(({ _id, ...optionRest }: any) => optionRest) || [];
                return { ...rest, options: updatedOptions, answers };
            }),
        };

        const questionDataPayload = {
            topicId: questionData?.value || questionData?.topicId?._id || '',
            subjectId: questionData?.subjectId?._id || '',
            subTopicId: questionData?.subTopicId || '',
            questionType: questionData.questionType || '',
            examCategory: questionData.examCategory || '',
            optionType: questionData.optionType || '',
            difficulty: questionData.difficulty || '',
            compData: [compData],
        };

        toast.promise(
            ViewService.updateCompQuestion(params.questionId, questionDataPayload).then((res) => {
                toast.success("Question Saved!")
                getQuestionByQuestionId();
            }),
            {
                loading: 'Uploading...',
                success: " Question Upload Successful.",
                error: "Unable to upload this question.",
            }
        );
    }

    const handleQuestionAttachements = async (e: any) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);
        await UploadService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                setQuestionData((obj: any) => ({
                    ...obj,
                    attachments: [...obj.attachments, res.data]
                }));
            }
        }).catch(e => {
            toast.error("Something went wrong when uploading Question Attachments");
        })
    }

    const getEncMediaImage = async (link: string) => {
        await ViewService.getEncMultimedia(link).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }

    const deleteAttachment = (index: any) => {
        setQuestionData((obj: any) => ({
            ...obj,
            attachments: obj.attachments.filter(
                (_: any, i: number) => i !== index
            ),
        }));
    };


    return (
        <>
            <div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <h5 className="fw-bold">Edit Question <span className="text-muted">(#{questionData._id})</span></h5>

                    </div>
                    <div>
                        {questionData.isVerified ? <Badge bg="success">Verified</Badge> : <Badge bg="warning">Not Verified</Badge>}
                    </div>
                    <div className='mt-4'>
                        {questionData ?
                            <>
                                {questionData.verification_issues && questionData.verification_issues.length > 0 &&
                                    <ViewVerificationIssues issues={questionData.verification_issues} reloadQuestionData={getQuestionByQuestionId} />}

                                <div className="mt-3">
                                    <Card className="reg-card box-shadow">
                                        <Card.Body>
                                            <h6 className="mt-2 fw-bold text-secondary">Question Image Attachments</h6>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Question Image Attachemnts</Form.Label>
                                                    <Form.Control type="file" name="image" accept="image/png, image/jpeg" onChange={handleQuestionAttachements} />
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Uploads</Form.Label>
                                                    {questionData && questionData.attachments && questionData.attachments.length > 0 ? questionData.attachments.map((attachment: any, index: number) => {
                                                        return (
                                                            <div>
                                                                <p className="text-muted xcn-text-10 fw-bold" >Image {index + 1} <FontAwesomeIcon icon={faEye} className="ms-2" onClick={() => { getEncMediaImage(attachment); setShowPreviewModal(true) }} />
                                                                    <span><FontAwesomeIcon icon={faTrash} className="ms-3 text-danger" onClick={() => deleteAttachment(index)} /></span>
                                                                </p>
                                                            </div>
                                                        )
                                                    }) : <p className="xcn-text-12 text-secondary">No attachents uploaded</p>}
                                                    <PreviewModal
                                                        type="image"
                                                        show={showPreviewModal}
                                                        onHide={() => setShowPreviewModal(false)}
                                                        image={encData}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='mt-3'>
                                    <Card className="reg-card box-shadow">
                                        <Card.Body>
                                            <h6 className="mt-2 fw-bold text-secondary">Question Data</h6>
                                            <ViewQuestion
                                                questionData={questionData}
                                                setQuestionData={setQuestionData}
                                                saveQuestion={questionData.questionType === "comprehension" ? saveCompQuestionData : saveQuestion}
                                                correctAnswer={correctAnswer}
                                                setCorrectAnswer={setCorrectAnswer}
                                                setQuestionText={setQuestionText}
                                                compCorrectAnswer={compCorrectAnswer}
                                                setCompCorrectAnswer={setCompCorrectAnswer}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                            </>
                            : <>Loading Question Please Wait..</>
                        }
                    </div>
                </div>
                <div>
                </div>

            </div>
        </>
    )
}