

export default class HandleChangeHelper {
    static reactSelectHandleChange(name: string, value: any){
        return {
            target: {
                name: name,
                value: value
            }
        }
    }
}